import {
  CheckCircledIcon,
  CopyIcon,
  CrossCircledIcon,
  DownloadIcon,
  InputIcon,
  LoopIcon,
  Pencil2Icon,
  ReloadIcon,
  ResetIcon,
  RowsIcon,
  ShuffleIcon,
  TrashIcon,
  UpdateIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Spinner,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CustomIcon } from 'components/common/custom-icon';
import {
  BaseballIcon,
  BetaIcon,
  SuperAdminIcon,
} from 'components/common/custom-icon/shorthands';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { CopyPitchesDialogHoC } from 'components/common/dialogs/copy-pitches';
import { PitchDataDialog } from 'components/common/dialogs/pitch-data';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSimpleFileUploader } from 'components/common/file-uploader';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { RemoteKeydownListener } from 'components/common/listeners/remote-keydown';
import { CommonLogs, ILog } from 'components/common/logs';
import { ManageCardDialog } from 'components/common/pitch-lists/manage-card';
import { ManageListDialogHoC } from 'components/common/pitch-lists/manage-list';
import { CommonTable } from 'components/common/table';
import { TableContext, TableProvider } from 'components/common/table/context';
import { CommonTooltip } from 'components/common/tooltip';
import { ActiveCalibrationModelWarning } from 'components/common/warnings/active-calibration-model-warning';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { MachineUnavailableButton } from 'components/machine/buttons/unavailable';
import { PresetTrainingDialog } from 'components/machine/dialogs/preset-training';
import { TrainingDialog } from 'components/machine/dialogs/training';
import {
  ChangeVideoDialog,
  DeletePitchesDialog,
  EditBreaksDialog,
  EditPitchDialog,
  EditSpinsDialog,
  OptimizePitchDialog,
  RefreshListDialog,
  RenameFolderDialog,
  ResetTrainingDialog,
} from 'components/sections/pitch-list/dialogs';
import { Header } from 'components/sections/pitch-list/header';
import { PitchListSidebar } from 'components/sections/pitch-list/sidebar';
import {
  MAX_SEARCH_LIMIT,
  SEARCH_ID,
} from 'components/sections/pitch-list/store/pitch-list-store';
import {
  PitchListStoreProvider,
  usePitchListStore,
} from 'components/sections/pitch-list/store/use-pitch-list-store';
import PitchListToolbar from 'components/sections/pitch-list/toolbar';
import { PitchesHeader } from 'components/sections/pitches/header';
import env from 'config';
import { AimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { GlobalContext } from 'contexts/global.context';
import { HittersContext } from 'contexts/hitters.context';
import {
  CheckedContext,
  CheckedProvider,
} from 'contexts/layout/checked.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import {
  MatchingShotsContext,
  ONLY_ALLOW_REFRESH_ON_TRAIN,
} from 'contexts/pitch-lists/matching-shots.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { SectionsContext } from 'contexts/sections.context';
import {
  ITrainingContext,
  TrainingContext,
  TrainingProvider,
} from 'contexts/training.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { CustomIconPath } from 'enums/custom.enums';
import { LOCAL_DATETIME_FORMAT_SHORT, LOCAL_TIMEZONE } from 'enums/env';
import { MachineButtonMode, ResetPlateMode } from 'enums/machine.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import { t } from 'i18next';
import { appearanceImgPath } from 'index';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { IStatusColumn } from 'interfaces/i-pitch-list';
import { IQueueDefinition, QueueID } from 'interfaces/i-queue-mode';
import { ITableAction } from 'interfaces/tables/action';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableReorder } from 'interfaces/tables/reordering';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { PlateHelper } from 'lib_ts/classes/plate.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { MLB_OUTCOME_CODES } from 'lib_ts/enums/mlb-stats-api/guid-metadata-types.enum';
import { PitchListDialog } from 'lib_ts/enums/pitch-list.enums';
import {
  BuildPriority,
  PitchListExtType,
  SHUFFLE_FREQUENCY_OPTIONS,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IFireResponseMsg,
  ITrainingMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import { IPitch, IPitchList } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useRef, useState } from 'react';
import { MainService } from 'services/main.service';
import { SessionEventsService } from 'services/session-events.service';
import slugify from 'slugify';
import { useShallow } from 'zustand/react/shallow';

const COMPONENT_NAME = 'PitchList';

// if true, training pitches that can be refreshed will refresh them without user confirmation
const AUTO_REFRESH_ON_TRAIN = true;

const ENABLE_LOGS = false;

// ms to wait after a remote command is received before actually sending mstarget to machine
const DELAY_REMOTE_SEND_MS = 2_000;

// group name starts with _ so that it renders as a separator in the menu
enum ActionGroup {
  Primary = '_1',
  Secondary = '_2',
  Tertiary = '_3',
}

const REPEAT_ONE: IQueueDefinition = {
  id: QueueID.RepeatOne,
  label: 'Repeat One',
  tooltip: 'Repeat a pitch until a new one is selected.',
  icon: <CustomIcon icon={CustomIconPath.RepeatOne} />,
};

const REPEAT_ALL: IQueueDefinition = {
  id: QueueID.RepeatAll,
  label: 'Repeat All',
  tooltip: 'Repeat all pitches in list according to the current sequence.',
  icon: <LoopIcon />,
};

const SHUFFLE_EACH: IQueueDefinition = {
  id: QueueID.ShuffleEach,
  label: 'Shuffle',
  tooltip: 'Select a random next pitch after each fire.',
  icon: <ShuffleIcon />,
};

const Q_DEFINITIONS: IQueueDefinition[] = [
  REPEAT_ALL,
  REPEAT_ONE,
  SHUFFLE_EACH,
];

interface IQueueOptions {
  action: 'add' | 'remove';
  id: string;
}

const IDENTIFIER = TableIdentifier.PitchList;

const Q_SORT_KEY = '_queue_sort';

const PAGE_SIZES = TABLES.PAGE_SIZES.XL;

const DEFAULT_QUEUE_DEF = REPEAT_ONE;

const MACHINE_BTN_CLASSES = 'width-200px text-titlecase';

interface IQueueState {
  /** ids of pitches from active list, in order set by queue mode */
  queuePitchIDs: string[];
  queueDef: IQueueDefinition;
}

interface IState extends IQueueState {
  /** for feedback to user without resorting to toasts */
  logs: ILog[];

  ignoreAutoFire: boolean;

  // forces table to redraw (e.g. to update training status per pitch after training)
  tableKey: number;
}

const MIN_SHUFFLE_FREQUENCY = parseInt(SHUFFLE_FREQUENCY_OPTIONS[0].value);

interface IPitchListHocProps {
  search?: boolean;
}

// Could move the PitchListStoreProvider outside of this, like how the Pitch List Context provider is set up
export const PitchListHoc = ({ search }: IPitchListHocProps) => {
  return (
    <TableProvider>
      <PitchListStoreProvider search={search}>
        <PitchList />
      </PitchListStoreProvider>
    </TableProvider>
  );
};

const PitchList = () => {
  const listStore = usePitchListStore(
    useShallow(({ pitches }) => ({
      pitches,
    }))
  );

  return (
    <CheckedProvider data={listStore.pitches}>
      <PitchListBase />
    </CheckedProvider>
  );
};

const PitchListBase = () => {
  const globalCx = useContext(GlobalContext);
  const cookiesCx = useContext(CookiesContext);
  const authCx = useContext(AuthContext);
  const hittersCx = useContext(HittersContext);
  const listsCx = useContext(PitchListsContext);
  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);
  const designCx = useContext(PitchDesignContext);
  const sectionsCx = useContext(SectionsContext);
  const videosCx = useContext(VideosContext);
  const aimingCx = useContext(AimingContext);
  const checkedCx = useContext(CheckedContext);
  const tableCx = useContext(TableContext);

  const listStore = usePitchListStore(
    useShallow(
      ({
        loading,
        active,
        tags,
        searchCriteria,
        pitches,
        managePitches,
        dialogData,
        dialogEdit,
        dialogEditBreaks,
        dialogEditSpins,
        dialogOptimize,
        dialogChangeVideo,
        dialogCopy,
        dialogReset,
        dialogTraining,
        dialogDeleteList,
        dialogDeletePitches,
        dialogResetList,
        dialogCopyList,
        dialogEditList,
        dialogCard,
        dialogRenameFolder,
        dialogSearch,
        setSearchCriteria,
        setTags,
        updatePitches,
        reloadPitches,
        activeReadOnly,
        updateTrainingStatus,
        rebuild,
        openDialog,
        closeDialog,
        onSearch,
      }) => ({
        loading,
        active,
        tags,
        searchCriteria,
        pitches,
        managePitches,
        dialogData,
        dialogEdit,
        dialogEditBreaks,
        dialogEditSpins,
        dialogOptimize,
        dialogChangeVideo,
        dialogCopy,
        dialogReset,
        dialogTraining,
        dialogDeleteList,
        dialogDeletePitches,
        dialogResetList,
        dialogCopyList,
        dialogEditList,
        dialogCard,
        dialogRenameFolder,
        dialogSearch,
        setSearchCriteria,
        setTags,
        updatePitches,
        reloadPitches,
        activeReadOnly,
        updateTrainingStatus,
        rebuild,
        openDialog,
        closeDialog,
        onSearch,
      })
    )
  );

  const sendTimeout = useRef<NodeJS.Timeout>();
  const refreshTimeout = useRef<NodeJS.Timeout>();
  const fileInput = useRef<CommonSimpleFileUploader>();
  const fireButton = useRef<MachineFireButton>();

  /** for auto-advancing through the queue,
   * when set, next time the pitch with matching ID is set as selectedPitch,
   * automatically send to machine
   */
  const autoSendPitchID = useRef<string>();

  /** will be consumed (and reset to undefined) whenever a pitch preview msg is sent */
  const changedFromRemote = useRef<boolean>();

  /**
   * when set, a timeout will be created to avoid spamming mstarget messages
   */
  const delaySendTargetMS = useRef<number>();

  // Reference to store the previous matchingCx.aggReady value
  const prevAggReadyRef = useRef(matchingCx.aggReady);

  // TODO: Refactor queue system
  const [state, setState] = useState<IState>({
    queueDef: DEFAULT_QUEUE_DEF,
    queuePitchIDs: [],

    logs: [],

    ignoreAutoFire: true,

    tableKey: Date.now(),
  });

  // this useEffect with an empty dependency array will run on every render, much like componentDidUpdate
  useEffect(() => {
    if (
      authCx.restrictedGameStatus() &&
      listStore.dialogTraining !== undefined
    ) {
      // auto-end training (if necessary) upon start of game
      NotifyHelper.warning({
        message_md: `Training is not allowed during home games.`,
      });

      listStore.closeDialog({
        dialog: PitchListDialog.DialogTraining,
      });
      // Add a useEffect and call onEndTraining there if necessary
      onEndTraining(listStore.managePitches);
    }

    // this should only trigger at most once, after that will only work when forced
    // Check if aggReady has transitioned from false to true
    if (!prevAggReadyRef.current && matchingCx.aggReady) {
      changeQueue(state.queueDef.id);
    }

    // Update the previous aggReady ref for the next render
    prevAggReadyRef.current = matchingCx.aggReady;
  });

  const getActions = () => {
    const restricted = authCx.restrictedGameStatus();
    const readonly = listStore.activeReadOnly();

    const output: ITableAction[] = [
      {
        group: ActionGroup.Primary,
        label: 'pl.open-pitch-list',
        color: RADIX.COLOR.SUCCESS,
        invisibleFn: () => listStore.active?._id !== SEARCH_ID,
        onClick: (pitch: IPitch) => {
          sectionsCx.tryChangeSection({
            trigger: 'go to list from search results',
            section: SectionName.Pitches,
            subsection: SubSectionName.List,
            fragments: [pitch._parent_id],
          });
        },
      },
      {
        group: ActionGroup.Primary,
        label: 'pl.view-pitch-data',
        onClick: (pitch: IPitch) =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogData,
            pitches: [pitch],
          }),
      },
      {
        group: ActionGroup.Primary,
        label: 'common.train-pitch',
        color: RADIX.COLOR.TRAIN_PITCH,
        invisibleFn: () =>
          env.identifier !== 'local' && !matchingCx.readyToTrain(),
        onClick: (pitch: IPitch) =>
          handleTrainPitches({
            ids: [pitch._id],
            promptRefresh: true,
          }),
      },
      {
        group: ActionGroup.Primary,
        label: 'main.pitch-design',
        invisibleFn: () => restricted,
        onClick: (pitch: IPitch) => {
          const refPitch = listStore.pitches.find((p) => p._id === pitch._id);

          if (!refPitch) {
            return;
          }

          if (!readonly) {
            /** allow design context to load up the pitch data */
            sectionsCx.tryChangeSection({
              trigger: 'PitchList > context menu > update',
              section: SectionName.Pitches,
              subsection: SubSectionName.Design,
              fragments: [refPitch._id],
            });
            return;
          }

          /** empty pitch _id => pitch design will only allow saving as a new pitch */
          designCx.setReference({
            ...refPitch,
            _id: '',
          });

          sectionsCx.tryChangeSection({
            trigger: 'PitchList > context menu > readonly',
            section: SectionName.Pitches,
            subsection: SubSectionName.Design,
          });
        },
      },
      {
        group: ActionGroup.Primary,
        label: 'pl.edit-pitch-metadata',
        invisibleFn: () => readonly || restricted,
        onClick: (pitch: IPitch) =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogEdit,
            pitches: [pitch],
          }),
      },
      {
        group: ActionGroup.Primary,
        label: 'pl.change-video',
        invisibleFn: () => readonly,
        onClick: (pitch: IPitch) =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogChangeVideo,
            pitches: [pitch],
          }),
      },
      {
        group: ActionGroup.Secondary,
        label: 'pl.optimize-pitch',
        suffixIcon: <BetaIcon />,
        disableFn: () =>
          !matchingCx.readyToTrain({
            ignoreConnection: true,
            ignoreGameStatus: true,
          }),
        invisibleFn: (pitch: IPitch) => {
          if (!env.enable.auto_beta && !authCx.current.enable_beta) {
            return true;
          }

          // hide if the pitch is not trained
          const matches = matchingCx.getAggShotsByPitch(pitch);
          return !matches?.trained;
        },
        onClick: async (pitch: IPitch) => {
          await matchingCx.updatePitch(
            {
              pitch: pitch,
              includeHitterPresent: false,
              includeLowConfidence: false,
            },
            true
          );

          listStore.openDialog({
            dialog: PitchListDialog.DialogOptimize,
            pitches: [pitch],
          });
        },
      },
      {
        group: ActionGroup.Secondary,
        label: 'pl.edit-breaks',
        disableFn: () =>
          !matchingCx.readyToTrain({
            ignoreConnection: true,
            ignoreGameStatus: true,
          }),
        invisibleFn: (pitch: IPitch) => {
          // hide if the pitch is not trained
          const matches = matchingCx.getAggShotsByPitch(pitch);
          return !matches?.trained;
        },
        onClick: async (pitch: IPitch) => {
          await matchingCx.updatePitch(
            {
              pitch: pitch,
              includeHitterPresent: false,
              includeLowConfidence: false,
            },
            true
          );

          listStore.openDialog({
            dialog: PitchListDialog.DialogEditBreaks,
            pitches: [pitch],
          });
        },
      },
      {
        group: ActionGroup.Secondary,
        label: 'pl.edit-spins',
        suffixIcon: <BetaIcon />,
        disableFn: () =>
          !matchingCx.readyToTrain({
            ignoreConnection: true,
            ignoreGameStatus: true,
          }),
        invisibleFn: (pitch: IPitch) => {
          if (!env.enable.auto_beta && !authCx.current.enable_beta) {
            return true;
          }

          // hide if the pitch is not trained
          const matches = matchingCx.getAggShotsByPitch(pitch);
          return !matches?.trained;
        },
        onClick: async (pitch: IPitch) => {
          await matchingCx.updatePitch(
            {
              pitch: pitch,
              includeHitterPresent: false,
              includeLowConfidence: false,
            },
            true
          );

          listStore.openDialog({
            dialog: PitchListDialog.DialogEditSpins,
            pitches: [pitch],
          });
        },
      },
      {
        group: ActionGroup.Secondary,
        label: t('common.copy-x', { x: t('common.pitch') }),
        invisibleFn: () => restricted,
        onClick: (pitch: IPitch) => {
          const originalPitch = listStore.pitches.find(
            (p) => p._id === pitch._id
          );
          if (!originalPitch) {
            NotifyHelper.error({
              message_md:
                'There was an error, please try again after refreshing.',
            });
            console.error({
              event: `${COMPONENT_NAME}: failed to find selected pitch in active context.`,
              pitch,
            });
            return;
          }

          listStore.openDialog({
            dialog: PitchListDialog.DialogCopy,
            pitches: [originalPitch],
          });
        },
      },
      {
        group: ActionGroup.Tertiary,
        label: 'pl.refresh-model',
        invisibleFn: (pitch: IPitch) =>
          ONLY_ALLOW_REFRESH_ON_TRAIN ||
          readonly ||
          !matchingCx.readyToRefresh(pitch),
        onClick: (pitch: IPitch) => {
          listStore.openDialog({
            dialog: PitchListDialog.DialogResetList,
            pitches: [pitch],
          });
        },
        color: RADIX.COLOR.SUCCESS,
      },
      {
        group: ActionGroup.Tertiary,
        label: 'common.reset-training-data',
        invisibleFn: (pitch: IPitch) => {
          if (readonly) {
            return true;
          }

          // hide if the pitch has no shots
          const matches = matchingCx.getAggShotsByPitch(pitch);
          return !matches || matches.total === 0;
        },
        onClick: (pitch: IPitch) => {
          listStore.openDialog({
            dialog: PitchListDialog.DialogReset,
            pitches: [pitch],
          });
        },
        color: RADIX.COLOR.WARNING,
      },
      {
        group: ActionGroup.Tertiary,
        label: t('common.delete-x', { x: t('common.pitch') }),
        invisibleFn: () => readonly || restricted,
        onClick: (pitch: IPitch) =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogDeletePitches,
            pitches: [pitch],
          }),
        color: RADIX.COLOR.DANGER,
      },
    ];

    return output;
  };

  const BASE_COLUMNS: ITableColumn[] = [
    {
      label: '#',
      key: Q_SORT_KEY,
      align: 'center',
      thClassNameFn: () => 'width-40px',
      classNameFn: () => 'width-40px',
      sortRowsFn: (a: IPitch, b: IPitch, dir: number) => {
        const aIndex = state.queuePitchIDs.findIndex((id) => id === a._id);
        const bIndex = state.queuePitchIDs.findIndex((id) => id === b._id);

        if (aIndex === -1 && bIndex === -1) {
          /** neither are in queue, don't change order */
          return 0;
        }

        if (aIndex === -1) {
          /** only b is in queue, put a after */
          return 1;
        }

        if (bIndex === -1) {
          /** only a is in queue, put a first */
          return -1;
        }

        /** both are in queue, sort by order */
        return (aIndex > bIndex ? -1 : 1) * dir;
      },
      formatFn: (pitch: IPitch) => {
        const showSpinner =
          machineCx.lastPitchID === pitch._id &&
          fireButton.current?.getFiring();

        if (showSpinner) {
          return <Spinner />;
        }

        if (state.queueDef.id !== QueueID.RepeatAll) {
          // not the right mode
          return;
        }

        const index = state.queuePitchIDs.findIndex((id) => id === pitch._id);

        if (index === -1) {
          // not in queue
          return;
        }

        return index + 1;
      },
    },
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: getActions(),
    },
    {
      label: 'pl.status',
      key: '_status',
      align: 'center',
      thClassNameFn: () => 'width-80px',
      tooltipFn: (pitch: IPitch) => {
        const status = getStatusColumn(pitch);

        const lines: string[] = [`${t('pl.status')}: ${status.text}`];

        const ms = getMSFromMSDict(pitch, machineCx.machine).ms;
        if (ms) {
          lines.push(
            `${t('common.model')}: ${machineCx.getModelName(ms.model_id)}`
          );
        }

        if (ms?.last_built) {
          lines.push(
            `${t('common.updated')}: ${format(
              parseISO(ms.last_built),
              LOCAL_DATETIME_FORMAT_SHORT,
              { timeZone: LOCAL_TIMEZONE }
            )}`
          );
        }

        return lines.join('\n\n');
      },
      sortRowsFn: (a: IPitch, b: IPitch, dir: number) => {
        const aCol = getStatusColumn(a);
        const bCol = getStatusColumn(b);

        return dir * (aCol.sortValue > bCol.sortValue ? -1 : 1);
      },
      formatFn: (pitch: IPitch) => {
        const summary = matchingCx.getAggShotsByPitch(pitch);
        const status = getStatusColumn(pitch);
        const canRefresh = matchingCx.readyToRefresh(pitch);

        return (
          <Box
            pt="1"
            data-testid="ShotSummary"
            data-total={summary?.total ?? 0}
            data-qt={summary?.qt ?? 0}
            data-qt-complete={summary?.qt_complete ?? false}
          >
            {!AUTO_REFRESH_ON_TRAIN && canRefresh ? (
              <UpdateIcon />
            ) : (
              status.icon
            )}
          </Box>
        );
      },
    },
    {
      label: 'common.pitch',
      key: 'name',
      formatFn: (pitch: IPitch) => {
        return getPitchColumnText(pitch);
      },
      tooltipFn: (pitch: IPitch) => {
        const items: { label: string; value?: string }[] = [
          { label: 'common.name', value: pitch.name },
          { label: 'common.year', value: pitch.year },
          { label: 'common.game', value: pitch.game },
          { label: 'common.hitter', value: pitch.hitter },
          {
            label: 'common.outcome',
            value:
              MLB_OUTCOME_CODES.find((o) => o.value === pitch.outcome)?.label ??
              pitch.outcome,
          },
        ].filter((i) => i.value);

        if (items.length === 0) {
          return;
        }

        return items.map((l) => `${t(l.label)}: ${l.value}`).join('\n\n');
      },
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const textA = getPitchColumnText(pitchA);
        const textB = getPitchColumnText(pitchB);
        return -dir * textA.localeCompare(textB);
      },
    },
    {
      label: 'common.priority',
      key: 'priority',
      formatFn: (pitch: IPitch) =>
        t(
          pitch.priority === BuildPriority.Breaks
            ? 'common.break'
            : 'common.spin'
        ),
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const textA = pitchA.priority ?? BuildPriority.Spins;
        const textB = pitchB.priority ?? BuildPriority.Spins;
        return -dir * textA.localeCompare(textB);
      },
    },
    {
      label: 'common.type',
      key: 'type',
    },
    {
      label: 'common.hand',
      key: 'hand',
      align: 'center',
      formatFn: (pitch: IPitch) => {
        const isRight = Math.sign(pitch.bs.px) <= 0;
        return (
          <Badge color={isRight ? RADIX.COLOR.RIGHT : RADIX.COLOR.LEFT}>
            {t(isRight ? 'common.rhp' : 'common.lhp')}
          </Badge>
        );
      },
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        return (
          dir * (Math.sign(pitchA.bs.px) < Math.sign(pitchB.bs.px) ? 1 : -1)
        );
      },
    },
    {
      label: 'common.zone',
      key: '_zone',
      align: 'center',
      formatFn: (pitch: IPitch) => {
        const summary = PlateHelper.getPitchSummary(pitch);
        return (
          <img
            alt="strike zone icon"
            className="StrikeZoneIcon"
            width={24}
            height={24}
            src={appearanceImgPath(`plate/${summary.grid}.svg`)}
          />
        );
      },
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const sortA = PlateHelper.getPitchSummary(pitchA).sort;
        const sortB = PlateHelper.getPitchSummary(pitchB).sort;
        return -dir * (sortA < sortB ? -1 : 1);
      },
    },
    {
      label: 'common.speed',
      key: 'speed',
      subLabel: 'mph',
      align: 'right',
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const va = pitchA.bs.vnet ?? BallHelper.getSpeed(pitchA.traj);
        const vb = pitchB.bs.vnet ?? BallHelper.getSpeed(pitchB.traj);
        return dir * (va < vb ? 1 : -1);
      },
      formatFn: (pitch: IPitch) => {
        return TrajHelper.getSpeedMPH(pitch.traj)?.toFixed(1);
      },
    },
    {
      label: 'common.spin',
      key: 'spin',
      subLabel: 'rpm',
      align: 'right',
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const va = pitchA.bs.wnet ?? BallHelper.getNetSpin(pitchA.bs);
        const vb = pitchB.bs.wnet ?? BallHelper.getNetSpin(pitchB.bs);
        return dir * (va < vb ? 1 : -1);
      },
      formatFn: (pitch: IPitch) => {
        return (pitch.bs.wnet ?? BallHelper.getNetSpin(pitch.bs)).toFixed(0);
      },
    },
    {
      label: 'common.h-break',
      key: 'breaks_xInches',
      subLabel: 'in',
      align: 'right',
      tooltipFn: () => PitchDesignHelper.HB_TOOLTIP_TEXT,
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        // don't change order
        if (
          pitchA.priority !== BuildPriority.Breaks &&
          pitchB.priority !== BuildPriority.Breaks
        ) {
          return 0;
        }

        // puts all non-breaks stuff after breaks stuff, regardless of direction
        if (pitchA.priority !== BuildPriority.Breaks) {
          return 1;
        }

        if (pitchB.priority !== BuildPriority.Breaks) {
          return -1;
        }

        const va = PitchListHelper.getSafePitchBreaks(pitchA)?.xInches;
        const vb = PitchListHelper.getSafePitchBreaks(pitchB)?.xInches;

        // don't change order
        if (va === undefined && vb === undefined) {
          return 0;
        }

        if (va === undefined) {
          return 1;
        }

        if (vb === undefined) {
          return -1;
        }

        return dir * (va > vb ? -1 : 1);
      },
      formatFn: (pitch: IPitch) => {
        if (pitch.priority !== BuildPriority.Breaks) {
          return;
        }

        const value = PitchListHelper.getSafePitchBreaks(pitch)?.xInches;

        if (value === undefined) {
          return;
        }

        return (-1 * value).toFixed(1);
      },
    },
    {
      label: 'common.v-break',
      key: 'breaks_zInches',
      subLabel: 'in',
      align: 'right',
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        // don't change order
        if (
          pitchA.priority !== BuildPriority.Breaks &&
          pitchB.priority !== BuildPriority.Breaks
        ) {
          return 0;
        }

        // puts all non-breaks stuff after breaks stuff, regardless of direction
        if (pitchA.priority !== BuildPriority.Breaks) {
          return 1;
        }

        if (pitchB.priority !== BuildPriority.Breaks) {
          return -1;
        }

        const va = PitchListHelper.getSafePitchBreaks(pitchA)?.zInches;
        const vb = PitchListHelper.getSafePitchBreaks(pitchB)?.zInches;

        // don't change order
        if (va === undefined && vb === undefined) {
          return 0;
        }

        if (va === undefined) {
          return 1;
        }

        if (vb === undefined) {
          return -1;
        }

        return dir * (va > vb ? -1 : 1);
      },
      formatFn: (pitch: IPitch) => {
        if (pitch.priority !== BuildPriority.Breaks) {
          return;
        }

        const value = PitchListHelper.getSafePitchBreaks(pitch)?.zInches;

        if (value === undefined) {
          return;
        }

        return value.toFixed(1);
      },
    },
    {
      label: 'common.frequency',
      key: 'frequency',
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const va = pitchA.frequency ?? MIN_SHUFFLE_FREQUENCY;
        const vb = pitchB.frequency ?? MIN_SHUFFLE_FREQUENCY;
        return dir * (va > vb ? -1 : 1);
      },
      formatFn: (pitch: IPitch) => (
        <CommonSelectInput
          key={`${pitch._id}-frequency`}
          id="PL-PitchFrequency"
          options={SHUFFLE_FREQUENCY_OPTIONS}
          value={(pitch.frequency ?? MIN_SHUFFLE_FREQUENCY).toString()}
          onOptionalNumericChange={(v) => {
            pitch.frequency = v;

            // silently should not update the array => should not trigger table to re-render
            listStore.updatePitches({
              payloads: [{ _id: pitch._id, frequency: v }],
              silently: true,
            });
          }}
          skipSort
        />
      ),
    },
  ];

  // Helpers
  const getCanRefresh = (pitches: IPitch[]) => {
    return pitches
      .filter((p) => matchingCx.readyToRefresh(p))
      .filter((p) => {
        switch (p.priority) {
          case BuildPriority.Breaks: {
            return !!machineCx.activeModel?.supports_breaks;
          }

          case BuildPriority.Spins:
          case BuildPriority.Default:
          default: {
            return !!machineCx.activeModel?.supports_spins;
          }
        }
      });
  };

  /** the server will emit trainingresponse even if not in training, e.g. for continuous data collection */
  const afterTrainingMsg = (data: ITrainingMsg) => {
    if (listStore.dialogTraining) {
      // training will handle the msgs while open
      return;
    }

    if (data.success === undefined) {
      return;
    }

    if (!data.success) {
      return;
    }

    /** updates the shots # in the table when necessary */
    aimingCx.updateMatches();
  };

  const handleTrainPitches = async (config: {
    ids: string[];
    promptRefresh: boolean;
  }) => {
    // always use pitches from the context in case they were (auto-)refreshed and differ from what's in selectedPitch or managePitches
    const pitches = listStore.pitches.filter((p) => config.ids.includes(p._id));

    if (pitches.length === 0) {
      return;
    }

    await matchingCx.updatePitches({
      pitches: pitches,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });

    const canRefresh = getCanRefresh(pitches);

    // by checking promptRefresh here, we guarantee we never loop indefinitely (e.g. if model refresh fails)
    if (canRefresh.length > 0 && config.promptRefresh) {
      const rebuildCallback = async () => {
        await listStore.rebuild(canRefresh.map((p) => p._id));

        clearTimeout(refreshTimeout.current);

        refreshTimeout.current = setTimeout(() => {
          if (!matchingCx.readyToTrain()) {
            NotifyHelper.warning({
              message_md:
                'Training cannot be performed at this time. Please try again later.',
            });
            return;
          }

          handleTrainPitches({ ...config, promptRefresh: false });
        }, 1_000);
      };

      if (AUTO_REFRESH_ON_TRAIN) {
        rebuildCallback();
        return;
      }

      NotifyHelper.warning({
        message_md: t('pl.x-pitches-refresh-msg', { x: canRefresh.length }),
        delay_ms: 0,
        buttons: [
          {
            label: 'pl.refresh-and-train',
            color: RADIX.COLOR.TRAIN_PITCH,
            onClick: rebuildCallback,
            dismissAfterClick: true,
          },
          {
            label: 'common.ignore',
            onClick: () => {
              if (!matchingCx.readyToTrain()) {
                NotifyHelper.warning({
                  message_md: 'common.training-unavailable-msg',
                });
                return;
              }

              handleTrainPitches({
                ...config,
                promptRefresh: false,
              });
            },
            dismissAfterClick: true,
          },
        ],
      });
      return;
    }

    // sidebar goes away, forces user to reselect a pitch after training
    aimingCx.setPitch(undefined);

    listStore.openDialog({
      dialog: PitchListDialog.DialogTraining,
      pitches: pitches,
    });
  };

  /** adds extra metadata to target before sending to machine */
  const sendSelected = (trigger: string, auto: boolean) => {
    if (globalCx.dialogs.length > 0) {
      return;
    }

    if (auto && !machineCx.checkActive(true)) {
      // silently skip auto-sending when not connected
      return;
    }

    const pitch = aimingCx.pitch;
    if (!pitch) {
      return;
    }

    if (
      auto &&
      (!autoSendPitchID.current || autoSendPitchID.current !== pitch._id)
    ) {
      // skip auto-sending when not necessary
      return;
    }

    /** ensure we don't auto-send more than once, e.g. if user re-selects the active row */
    if (autoSendPitchID.current === pitch._id) {
      autoSendPitchID.current = undefined;
    }

    const getQueuePitchByIndex = (
      index: number,
      delta: number
    ): IPitch | undefined => {
      if (!changedFromRemote.current) {
        // only show previous/next when changing from remote
        return;
      }

      if (state.queueDef.id === QueueID.ShuffleEach) {
        // shuffle should never indicate what comes next/previous because it's random
        return;
      }

      const modulus = state.queuePitchIDs.length;
      const safeIndex = (index + delta + modulus) % modulus;
      const id = state.queuePitchIDs[safeIndex];
      return listStore.pitches.find((p) => p._id === id);
    };

    const index = state.queuePitchIDs.findIndex((id) => id === pitch._id);

    machineCx.sendPitchPreview({
      trigger: COMPONENT_NAME,
      current: pitch,
      prev: getQueuePitchByIndex(index, -1),
      next: getQueuePitchByIndex(index, 1),
    });

    changedFromRemote.current = undefined;

    fireButton.current?.setAwaitingResend(true);

    const sendCallback = () => {
      aimingCx.sendToMachine({
        training: false,
        skipPreview: true,
        trigger: trigger,
        list: listsCx.lists.find((l) => l._id === pitch._parent_id),
        hitter: hittersCx.active,
        onSuccess: () => {
          fireButton.current?.setAwaitingResend(false);

          // reset the delay after sending, to avoid impacting non-delayed sends in the future
          delaySendTargetMS.current = undefined;
        },
      });
    };

    if (
      delaySendTargetMS.current === undefined ||
      delaySendTargetMS.current <= 0
    ) {
      sendCallback();
      return;
    }

    clearTimeout(sendTimeout.current);
    sendTimeout.current = setTimeout(sendCallback, delaySendTargetMS.current);
  };

  const getNextPitch = (delta: number): IPitch | undefined => {
    if (state.queueDef.id === QueueID.ShuffleEach) {
      const USE_BUCKET_LOTTERY = true;

      if (USE_BUCKET_LOTTERY) {
        // this algo picks a frequency bucket first and then picks a matching pitch from the bucket
        // e.g. with frequencies 1, 2, and 3 in use, the pool of values will be [1, 2, 2, 3, 3, 3]
        // it will pick a random value from this pool (the bucket), and then find all pitches with matching frequency value (items in the bucket)
        // then it will pick a random pitch from the matches
        // this ensures that have many pitches of the same frequency doesn't dilute all other frequencies
        const qPitches = state.queuePitchIDs
          .map((id) => listStore.pitches.find((p) => p._id === id))
          .filter((p) => p) as IPitch[];

        // get unique frequencies that occur in the queue
        const qFreqs: number[] = ArrayHelper.unique(
          qPitches.map((p) => p.frequency ?? MIN_SHUFFLE_FREQUENCY)
        );

        // weighted freq => higher numbers will show up more times
        const wFreqs: number[] = qFreqs.flatMap((f) => {
          const o: number[] = [];

          // e.g. 3 => 3 will show up 3 times
          for (let i = 0; i < f; i++) {
            o.push(f);
          }

          return o;
        });

        // pick one of the freq randomly
        const rFreq = wFreqs[Math.round(Math.random() * 1_000) % wFreqs.length];

        // get all pitches from the queue that have frequency === rFreq
        const freqPitches = qPitches.filter((p) => {
          const safeFreq = p.frequency ?? MIN_SHUFFLE_FREQUENCY;
          return safeFreq === rFreq;
        });

        const iNext =
          Math.round(Math.random() * 1_000_000) % freqPitches.length;
        return freqPitches[iNext];
      }

      // this algo enters each pitch into a raffle based on the frequency number and picks a random pitch from the raffle
      // e.g. a pitch with frequency 2 will be entered into the raffle 2x, whereas a pitch with frequency 1 will be entered 1x
      // a large number of pitches of a single frequency will tend to dilute the odds of others
      // 97 pitches of frequency 1 and 1 pitch of frequency 3 => the pitch with frequency 3 will only have a 3% or 3/100 (i.e. 97x1 + 1x3) chance of being selected
      // despite it being "high" frequency and everything else is "low"
      const weightedIDs: string[] = [];

      state.queuePitchIDs.forEach((id) => {
        const pitch = listStore.pitches.find((p) => p._id === id);

        if (!pitch) {
          return;
        }

        // undefined => 1
        const safeFreq = pitch.frequency ?? MIN_SHUFFLE_FREQUENCY;

        // higher frequency => more occurrences of the pitch in the list
        for (let i = 0; i < safeFreq; i++) {
          weightedIDs.push(pitch._id);
        }
      });

      const iNext = Math.round(Math.random() * 1_000_000) % weightedIDs.length;
      const nextID = weightedIDs[iNext];
      return listStore.pitches.find((p) => p._id === nextID);
    }

    const length = state.queuePitchIDs.length;
    if (length === 0) {
      return;
    }

    /** defaults to 0 if nothing was sent (e.g. user hits next/previous on remote before sending any pitch) */
    const current = tableCx.selectedData as IPitch | undefined;

    const iCurrent = state.queuePitchIDs.findIndex((id) => id === current?._id);

    /** adding length allows the function to work in reverse and loop around */
    const iNext = (iCurrent + length + delta) % length;

    const nextID = state.queuePitchIDs[iNext];
    return listStore.pitches.find((p) => p._id === nextID);
  };

  /**
   * tells table to update selection and send to machine, to be triggered by fire response
   * @param delta indicates how many pitches to move forwards (+) or backwards (-) in queue
   * @param delay_ms tells the app to wait before sending the next pitch
   * @param cascadeSend tells the app to send whatever is selected next
   */
  const changeActivePitch = async (config: {
    delta: number;
    delay_ms?: number;
    autoSend?: boolean;
  }) => {
    if (globalCx.dialogs.length > 0) {
      return;
    }

    const nextPitch = getNextPitch(config.delta);
    if (!nextPitch) {
      return;
    }

    if (config.delay_ms !== undefined && config.delay_ms > 0) {
      delaySendTargetMS.current = config.delay_ms;
    }

    /** make a note to send the pitch when it's next selected */
    autoSendPitchID.current = nextPitch._id;
    changedFromRemote.current = config.autoSend;

    await aimingCx.setPitch(nextPitch, {
      resetPlate: ResetPlateMode.PitchTraj,
      loadShots: true,
      sendConfig: config.autoSend
        ? {
            training: false,
            skipPreview: false,
            trigger: `${COMPONENT_NAME} > change active pitch`,
          }
        : undefined,
    });

    const c = tableCx.lookupCoordinates('_id', nextPitch._id);
    tableCx.setSelected({
      ...c,
    });
  };

  const handleFireResponse = (event: CustomEvent) => {
    console.debug('fireresponse', event);

    if (globalCx.dialogs.length > 0) {
      return;
    }

    const data: IFireResponseMsg = event.detail;

    if (!data) {
      NotifyHelper.error({
        message_md: `Empty fire response payload. ${ERROR_MSGS.CONTACT_SUPPORT}`,
      });
      return;
    }

    if (!data.status) {
      const message = data.message ?? 'Failed to fire, reason unknown';
      NotifyHelper.error({ message_md: message, inbox: true });
      return;
    }

    if (state.queueDef.id === QueueID.RepeatOne) {
      // don't auto-change pitch
      return;
    }

    changeActivePitch({
      delta: 1,
      autoSend: true,
    });
  };

  /** for active row selection functionality */
  const afterChangeSelected = async (pitch?: IPitch) => {
    if (!pitch) {
      return;
    }

    // table selected row changes when firing within a queue without user input
    const autoSend = pitch && pitch._id === autoSendPitchID.current;

    await aimingCx.setPitch(pitch, {
      resetPlate: ResetPlateMode.PitchTraj,
      loadShots: true,
      sendConfig: autoSend
        ? {
            training: false,
            skipPreview: false,
            trigger: `${COMPONENT_NAME} > after change selected`,
          }
        : undefined,
    });
  };

  // returns the IDs of pitches that can be queued
  const getQueueIDs = (options?: IQueueOptions): string[] => {
    const trained = tableCx.sortedData.filter((p) =>
      matchingCx.isPitchTrained(p)
    );

    const { getChecked } = checkedCx;

    const trainedAndChecked = trained
      .filter((p) => getChecked(p._id))
      .map((p) => p._id);

    if (!options && trainedAndChecked.length === 0) {
      // nothing is checked => queue is everything that is trained
      return trained.map((p) => p._id);
    }

    // at least one pitch is checked => queue should only contain trained AND checked pitches
    const nextQueue = state.queuePitchIDs.filter((id) => {
      const pitch = listStore.pitches.find((p) => p._id === id);

      if (!pitch) {
        // cannot find the pitch in the active list
        return false;
      }

      if (!trainedAndChecked.includes(id)) {
        // exclude previously queued pitch because it's not checked
        return false;
      }

      if (options && options.action === 'remove' && pitch._id === options.id) {
        // apply remove option
        return false;
      }

      // anything that will continue to be queued, leave intact
      return true;
    });

    // add new items to the end (e.g. something became trained or checked)
    trainedAndChecked.forEach((id) => {
      if (!nextQueue.includes(id)) {
        nextQueue.push(id);
      }
    });

    if (
      options &&
      options.action === 'add' &&
      !nextQueue.includes(options.id)
    ) {
      // apply add option
      nextQueue.push(options.id);
    }

    return nextQueue;
  };

  const changeQueue = async (type: QueueID, options?: IQueueOptions) => {
    setState((prev) => ({
      ...prev,
      tableKey: Date.now(),
      queueDef: Q_DEFINITIONS.find((m) => m.id === type) ?? DEFAULT_QUEUE_DEF,
      queuePitchIDs: getQueueIDs(options),
    }));
  };

  const getListActions = (): IMenuAction[] | undefined => {
    if (sectionsCx.active.subsection === SubSectionName.Library) {
      return;
    }

    if (anyLoading()) {
      return;
    }

    const activeList = listStore.active as IPitchList;

    if (!activeList) {
      return;
    }

    const restricted = authCx.restrictedGameStatus();

    const countTrained = getTrainingStatus(true).length;

    const canRefresh = getCanRefresh(listStore.pitches);

    const readonly = listStore.activeReadOnly();

    const actions: IMenuAction[] = [
      {
        group: '_1',
        label: 'common.edit-list',
        prefixIcon: <Pencil2Icon />,
        invisible: readonly || restricted,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogEditList,
          }),
      },
      {
        group: '_1',
        label: 'common.duplicate-list',
        prefixIcon: <CopyIcon />,
        invisible: restricted,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogCopyList,
          }),
      },
      {
        group: '_1',
        label: 'common.reorder-list',
        prefixIcon: <RowsIcon />,
        invisible: readonly || restricted,
        onClick: () => {
          /** reset queue before reorder to avoid confusion after reorder list is complete */
          setState((prev) => ({
            ...prev,
            queuePitchIDs: [],
          }));
          tableCx.showReorder();
        },
      },

      // divider

      {
        group: '_2',
        prefixIcon: <BaseballIcon />,
        label: 'common.create-pitch',
        disabled: authCx.restrictedGameStatus(),
        onClick: () => {
          // provide _parent_id to pre-select this list for save dialog
          const folderPitch = machineCx.getDefaultPitch();
          folderPitch._parent_id = activeList._id;

          designCx.setReference(folderPitch);

          sectionsCx.tryChangeSection({
            trigger: 'PitchesHeader > actions menu',
            section: SectionName.Pitches,
            subsection: SubSectionName.Design,
          });
        },
      },
      {
        group: '_2',
        prefixIcon: <UploadIcon />,
        label: 'common.upload-pitches',
        disabled: authCx.restrictedGameStatus(),
        onClick: () =>
          sectionsCx.tryChangeSection({
            trigger: 'PitchesHeader > actions menu',
            section: SectionName.Pitches,
            subsection: SubSectionName.Upload,
          }),
      },

      // divider

      {
        group: 'pl.bulk-edit',
        label: 'common.export-csv',
        prefixIcon: <DownloadIcon />,
        invisible: readonly || restricted || listStore.pitches.length === 0,
        onClick: () => {
          const pitches = listStore.pitches.map((p) =>
            PitchListHelper.convertToCustomExport({
              pitch: p,
              plate_ft: machineCx.machine.plate_distance,
              video: videosCx.getVideo(p.video_id),
            })
          );

          SessionEventsService.postEvent({
            category: 'pitch',
            tags: 'export',
            data: {
              event: 'list (all) to CSV',
              list_id: activeList._id,
              list_name: activeList.name,
              count: pitches.length,
            },
          });

          MainService.getInstance()
            .convertJSONToCSV(pitches)
            .then((csvString) => {
              const blob = new Blob([csvString], { type: 'text/csv' });
              MiscHelper.saveAs(blob, `${slugify(activeList.name)}.csv`);
            });
        },
      },
      {
        group: 'pl.bulk-edit',
        label: 'common.import-csv',
        prefixIcon: <UploadIcon />,
        invisible: readonly || restricted || listStore.pitches.length === 0,
        onClick: () => {
          if (fileInput.current) {
            fileInput.current.handleClick();
          } else {
            console.warn('no file input element');
          }
        },
      },

      // divider

      {
        group: '_3',
        label: 'pl.refresh-models',
        prefixIcon: <ReloadIcon />,
        invisible: ONLY_ALLOW_REFRESH_ON_TRAIN || canRefresh.length === 0,
        color: RADIX.COLOR.SUCCESS,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogResetList,
            pitches: canRefresh,
          }),
      },
      {
        group: '_3',
        label: 'common.reset-training-data',
        prefixIcon: <ResetIcon />,
        invisible: readonly || countTrained === 0,
        color: RADIX.COLOR.WARNING,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogReset,
            pitches: listStore.pitches,
          }),
      },
      {
        group: '_3',
        label: 'pl.rename-folder',
        prefixIcon: <InputIcon />,
        invisible: readonly || restricted || !activeList.folder,
        color: RADIX.COLOR.WARNING,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogRenameFolder,
          }),
      },
      {
        group: '_3',
        label: 'pl.manage-card',
        prefixIcon: <SuperAdminIcon />,
        invisible:
          authCx.current.role !== UserRole.admin ||
          activeList.type !== PitchListExtType.Card,
        color: RADIX.COLOR.SUPER_ADMIN,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogCard,
          }),
      },
      {
        group: '_3',
        label: 'common.delete',
        prefixIcon: <TrashIcon />,
        invisible: readonly || restricted,
        color: RADIX.COLOR.DANGER,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogDeleteList,
          }),
      },
    ];

    return actions;
  };

  const onChangeTrained = () => {
    changeQueue(state.queueDef.id);
    listStore.updateTrainingStatus();
  };

  /**
   * true if any of lists context, machine context, matching context, or model refresh are loading
   */
  // Probably doesn't need to be a function
  const anyLoading = () => {
    return (
      listStore.loading ||
      listsCx.loading ||
      machineCx.loading ||
      matchingCx.loading
    );
  };

  const getCheckedMenuActions = (checked: IPitch[]) => {
    if (checked.length === 0) {
      return [];
    }

    const isChecked = (id: string) =>
      checked.findIndex((p) => p._id === id) !== -1;

    const restricted = authCx.restrictedGameStatus();

    const countTrained = getTrainingStatus(true).filter((p) =>
      isChecked(p._id)
    ).length;

    const canRefresh = checked.filter((p) => matchingCx.readyToRefresh(p));

    const readonly = listStore.activeReadOnly();

    const actions: IMenuAction[] = [
      {
        label: 'common.train-pitches',
        invisible: !matchingCx.readyToTrain(),
        color: RADIX.COLOR.TRAIN_PITCH,
        onClick: () =>
          handleTrainPitches({
            ids: checked.map((p) => p._id),
            promptRefresh: true,
          }),
      },
      {
        label: 'pl.change-videos',
        invisible: readonly,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogChangeVideo,
            pitches: checked,
          }),
      },
      {
        label: 'pl.copy-pitches',
        invisible: restricted,
        onClick: () => {
          const checkedPitchIDs = checked.map((p) => p._id);
          const originalPitches = listStore.pitches.filter((p) =>
            checkedPitchIDs.includes(p._id)
          );

          if (originalPitches.length !== checkedPitchIDs.length) {
            NotifyHelper.error({
              message_md:
                'There was an error, please try again after refreshing.',
            });
            console.error({
              event: `${COMPONENT_NAME}: failed to find all checked pitches in active context.`,
              checkedPitches: checked,
              originalPitches,
            });
            return;
          }

          listStore.openDialog({
            dialog: PitchListDialog.DialogCopy,
            pitches: originalPitches,
          });
        },
      },
      {
        label: 'pl.export-pitches',
        invisible: restricted,
        onClick: async () => {
          if (!listStore.active) {
            NotifyHelper.warning({
              message_md: t('pl.no-active-pitch-list'),
            });
            return;
          }

          const activeList = listStore.active;
          if (!activeList) {
            // do nothing
            return;
          }

          const payload = checked.map((p) =>
            PitchListHelper.convertToCustomExport({
              pitch: p,
              plate_ft: machineCx.machine.plate_distance,
              video: videosCx.getVideo(p.video_id),
            })
          );

          SessionEventsService.postEvent({
            category: 'pitch',
            tags: 'export',
            data: {
              event: 'list (checked) to CSV',
              list_id: activeList._id,
              list_name: activeList.name,
              count: payload.length,
            },
          });

          const csvString =
            await MainService.getInstance().convertJSONToCSV(payload);

          const blob = new Blob([csvString], { type: 'text/csv' });
          MiscHelper.saveAs(blob, `${slugify(activeList.name)}-checked.csv`);
        },
      },
      {
        label: 'pl.refresh-models',
        invisible:
          ONLY_ALLOW_REFRESH_ON_TRAIN || readonly || canRefresh.length === 0,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogResetList,
            /** only refresh checked pitches that are outdated */
            pitches: canRefresh,
          }),
        color: RADIX.COLOR.SUCCESS,
      },
      {
        label: 'common.reset-training-data',
        invisible: readonly || countTrained === 0,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogReset,
            pitches: checked,
          }),
        color: RADIX.COLOR.WARNING,
      },
      {
        label: 'pl.delete-pitches',
        invisible: readonly || restricted,
        onClick: () =>
          listStore.openDialog({
            dialog: PitchListDialog.DialogDeletePitches,
            pitches: checked,
          }),
        color: RADIX.COLOR.DANGER,
      },
    ];

    return actions;
  };

  const getTrainingStatus = (trained: boolean): IPitch[] => {
    return listStore.pitches.filter(
      (p) => trained === matchingCx.isPitchTrained(p)
    );
  };

  const getPitchColumnText = (pitch: IPitch): string => {
    const parts: string[] = [];

    if (pitch.year) {
      parts.push(`[${pitch.year}]`);
    }

    parts.push(pitch.name || '(no name)');

    return parts.join(' ');
  };

  const getStatusColumn = (pitch: IPitch): IStatusColumn => {
    const shots = matchingCx.getAggShotsByPitch(pitch);

    const output: IStatusColumn = {
      sortValue: 0,
      trained: false,
      precision: false,
      text: 'Untrained',
      icon: (
        <Text color={RADIX.COLOR.NEUTRAL}>
          <CrossCircledIcon />
        </Text>
      ),
    };

    // whether the pitch is trained via QT or old fashioned way
    output.trained = !!shots?.trained;

    output.precision =
      !!shots?.trained &&
      !!getMSFromMSDict(pitch, machineCx.machine).ms?.precision_trained;

    if (output.precision) {
      output.sortValue = 2;
      output.text = 'Precision Trained';
      output.icon = (
        <Text color={RADIX.COLOR.SUCCESS}>
          <CustomIcon icon={CustomIconPath.CheckCircledFilled} />
        </Text>
      );
    } else if (output.trained) {
      output.sortValue = 1;
      output.text = 'Trained';
      output.icon = (
        <Text color={RADIX.COLOR.SUCCESS}>
          <CheckCircledIcon />
        </Text>
      );
    }

    return output;
  };

  const localMachineButtonMode = () => {
    return matchingCx.machineButtonMode({
      pitch: aimingCx.pitch,
      requireTraining: true,
      requireSend: aimingCx.checkRequireSend(),
      awaitingResend: fireButton.current?.getAwaitingResend(),
    });
  };

  const onEndTraining = (trained: IPitch[] | undefined) => {
    if (!trained || trained.length === 0) {
      return;
    }

    machineCx.resetMSHash();

    // deselect any row that was selected to force retrieval of shots on click
    tableCx.setSelected({
      page: 0,
      index: -1,
    });

    // get shots for anything that was just trained
    matchingCx
      .updatePitches({
        pitches: trained,
        includeHitterPresent: false,
        includeLowConfidence: true,
      })
      .then(() => {
        setState((prev) => ({
          ...prev,
          tableKey: Date.now(),
        }));

        onChangeTrained();
      });
  };

  // Create a stable reference to handleFireResponse so the WebSocket event listener doesn't keep getting added/removed
  const handleFireResponseRef = useRef(handleFireResponse);

  // Update the ref to always point to the latest handler definition
  useEffect(() => {
    handleFireResponseRef.current = handleFireResponse;
  }, [handleFireResponse]);

  useEffect(() => {
    const onFireResponse = (event: CustomEvent) => {
      if (handleFireResponseRef.current) {
        handleFireResponseRef.current(event);
      }
    };

    WebSocketHelper.on(WsMsgType.M2U_FireResponse, onFireResponse);

    return () => {
      WebSocketHelper.remove(WsMsgType.M2U_FireResponse, onFireResponse);

      machineCx.setAutoFire(false);

      clearTimeout(sendTimeout.current);
      clearTimeout(refreshTimeout.current);
    };
  }, []);

  // Note: The useEffect this was refactored from in listCx had searchCriteria as a dependency
  // It was removed from this useEffect and placed directly in listStore.setSearchCriteria for simplicity
  useDeepCompareEffect(() => {
    listStore.onSearch();
  }, [
    // run this on mount...
    listStore.onSearch,
    // when the active list changes...
    listStore.active?._id, // Do we have to watch for changes to other properties of the active list?
    // when pitch lists are refetched (e.g. when new pitches are uploaded via csv)
    listsCx.lastFetched,
  ]);

  const renderBody = () => {
    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      enablePagination: true,
      total: listStore.pitches.length,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      beforeSortFn: async () => {
        if (listStore.active?._id !== SEARCH_ID) {
          return;
        }

        if (listStore.searchCriteria.limit === MAX_SEARCH_LIMIT) {
          return;
        }

        // setting the search will cause the limit to be set to the max
        await listStore.setSearchCriteria(listStore.searchCriteria);
      },
    };

    const reorder: ITableReorder = {
      collection: 'pitches',
      mappingFn: (item: IPitch) => {
        return {
          label: item.name ?? t('pl.unnamed-pitch'),
          value: item._id,
        };
      },
    };

    const select: ITableSelectable = {
      enableSelect: true,
      blockSelect: matchingCx.loading,
      afterChangeSelected: (model: IPitch | undefined) =>
        afterChangeSelected(model),
    };

    const checkable: ITableCheckable = {
      checkboxColumnIndex: 1,
      checkedActions: getCheckedMenuActions,
      afterCheckOne: (pitchID, checked) => {
        changeQueue(state.queueDef.id, {
          action: checked ? 'add' : 'remove',
          id: pitchID,
        });
      },
      afterCheckMany: () => {
        changeQueue(state.queueDef.id);
      },
      afterCheckAll: () => {
        changeQueue(state.queueDef.id);
      },
    };

    return (
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={
          <>
            {renderHeader()}
            <ActiveCalibrationModelWarning showSettingsButton />
          </>
        }
        table={
          <>
            <CommonTable
              key={state.tableKey}
              id={COMPONENT_NAME}
              toolbarContent={<PitchListToolbar />}
              displayColumns={BASE_COLUMNS}
              displayData={listStore.pitches}
              rowClassNameFn={(pitch: IPitch) =>
                matchingCx.isPitchTrained(pitch) ? 'Sendable' : 'Trainable'
              }
              loading={listStore.loading}
              {...pagination}
              {...checkable}
              {...reorder}
              {...select}
              {...sort}
              vFlex
            />

            {renderDialogs()}
          </>
        }
        footer={
          <>
            {renderTableFooter()}

            {ENABLE_LOGS && <CommonLogs logs={state.logs} />}
          </>
        }
      />
    );
  };

  const renderDialogs = () => {
    const readOnly = listStore.activeReadOnly();

    return (
      <>
        {listStore.dialogCard && listStore.active && (
          <ManageCardDialog
            key={listStore.dialogCard}
            identifier="PitchListManageCardDialog"
            authCx={authCx}
            machineCx={machineCx}
            activeList={listStore.active}
            onClose={async () => {
              machineCx.resetMSHash();
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCard,
              });
            }}
          />
        )}

        {listStore.dialogCopyList && listStore.active && (
          <ManageListDialogHoC
            key={listStore.dialogCopyList}
            identifier="PitchListCopyListDialog"
            mode="copy"
            onCreated={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCopyList,
              })
            }
            onClose={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCopyList,
              })
            }
          />
        )}

        {listStore.dialogEditList && listStore.active && (
          <ManageListDialogHoC
            key={listStore.dialogEditList}
            identifier="PitchListEditListDialog"
            mode="edit"
            onCreated={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogEditList,
              })
            }
            onClose={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogEditList,
              })
            }
          />
        )}

        {listStore.dialogRenameFolder && listStore.active && (
          <RenameFolderDialog
            key={listStore.dialogRenameFolder}
            list={listStore.active}
            onClose={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogRenameFolder,
              })
            }
          />
        )}

        {listStore.dialogChangeVideo && listStore.managePitches && (
          <ChangeVideoDialog
            key={listStore.dialogChangeVideo}
            pitches={listStore.managePitches}
            onClose={async (result) => {
              if (!result) {
                listStore.closeDialog({
                  dialog: PitchListDialog.DialogChangeVideo,
                });
                return;
              }

              /** if selected pitch also had its video updated, update video details so the video preview updates */
              const changedPitch = result.find(
                (p) => p._id === aimingCx.pitch?._id
              );
              if (!changedPitch) {
                listStore.closeDialog({
                  dialog: PitchListDialog.DialogChangeVideo,
                });
                return;
              }

              /** force resend => new video needs to be rendered */
              await aimingCx.setPitch(changedPitch);

              listStore.closeDialog({
                dialog: PitchListDialog.DialogChangeVideo,
              });
            }}
          />
        )}

        {listStore.dialogCopy && listStore.managePitches && (
          <CopyPitchesDialogHoC
            key={listStore.dialogCopy}
            identifier="PitchListCopyPitchesDialog"
            reloadPitches={listStore.reloadPitches}
            title={t('common.copy-x', {
              x: t(
                listStore.managePitches.length === 1
                  ? 'common.pitch'
                  : 'common.pitches'
              ),
            }).toString()}
            description={t('pl.select-pitch-list-to-copy-into').toString()}
            pitches={listStore.managePitches}
            onCreated={() => {
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCopy,
              });
              // since you may have copied trained pitches back into this list
              changeQueue(state.queueDef.id);
            }}
            onClose={() =>
              listStore.closeDialog({
                dialog: PitchListDialog.DialogCopy,
              })
            }
          />
        )}

        {listStore.dialogEdit && listStore.managePitches && (
          <EditPitchDialog
            key={listStore.dialogEdit}
            pitch={listStore.managePitches[0]}
            onClose={async () => {
              machineCx.resetMSHash();

              listStore.closeDialog({
                dialog: PitchListDialog.DialogEdit,
              });
            }}
          />
        )}

        {listStore.dialogData && listStore.managePitches && (
          <PitchDataDialog
            key={listStore.dialogData}
            identifier="PitchListViewDataDialog"
            cookiesCx={cookiesCx}
            authCx={authCx}
            machine={machineCx.machine}
            pitch={listStore.managePitches[0]}
            onClose={() => {
              listStore.closeDialog({
                dialog: PitchListDialog.DialogData,
              });
            }}
            showTrainingData
          />
        )}

        {listStore.dialogOptimize && listStore.managePitches && (
          <OptimizePitchDialog
            key={listStore.dialogOptimize}
            pitch={listStore.managePitches[0]}
            machineCx={machineCx}
            matchingCx={matchingCx}
            readonly={readOnly}
            onClose={() => {
              listStore.closeDialog({
                dialog: PitchListDialog.DialogOptimize,
              });
              // since you may have deleted training data that untrains a pitch
              onChangeTrained();
            }}
          />
        )}

        {listStore.dialogEditBreaks && listStore.managePitches && (
          <EditBreaksDialog
            key={listStore.dialogEditBreaks}
            pitch={listStore.managePitches[0]}
            machineCx={machineCx}
            matchingCx={matchingCx}
            readonly={readOnly}
            onClose={async () => {
              machineCx.resetMSHash();
              listStore.closeDialog({
                dialog: PitchListDialog.DialogEditBreaks,
              });
              // since you may have modified a trained pitch to make it untrained
              onChangeTrained();
            }}
          />
        )}

        {listStore.dialogEditSpins && listStore.managePitches && (
          <EditSpinsDialog
            key={listStore.dialogEditSpins}
            pitch={listStore.managePitches[0]}
            machineCx={machineCx}
            matchingCx={matchingCx}
            readonly={readOnly}
            onClose={async () => {
              machineCx.resetMSHash();
              listStore.closeDialog({
                dialog: PitchListDialog.DialogEditSpins,
              });
              // since you may have modified a trained pitch to make it untrained
              onChangeTrained();
            }}
          />
        )}

        {listStore.dialogReset && listStore.managePitches && (
          <ResetTrainingDialog
            key={listStore.dialogReset}
            pitches={listStore.managePitches}
            onClose={() => {
              setState((prev) => ({
                ...prev,
                tableKey: Date.now(),
              }));
              listStore.closeDialog({
                dialog: PitchListDialog.DialogReset,
              });
              // since you may have modified a trained pitch to make it untrained
              onChangeTrained();
            }}
          />
        )}
      </>
    );
  };

  const renderHeader = () => {
    if (listStore.active?._id === SEARCH_ID) {
      return <PitchesHeader />;
    }

    const untrained = getTrainingStatus(false);

    return (
      <Header
        list={listStore.active}
        actions={getListActions()}
        mainAction={
          untrained.length === 0
            ? // don't allow train all from search view
              undefined
            : {
                label: t('pl.train-n-new-x', {
                  n: untrained.length,
                  x: t(
                    untrained.length === 1 ? 'common.pitch' : 'common.pitches'
                  ).toLowerCase(),
                }).toString(),
                disabled: !matchingCx.readyToTrain(),
                variant: 'soft',
                color: RADIX.COLOR.TRAIN_PITCH,
                className: 'text-titlecase',
                onClick: () =>
                  handleTrainPitches({
                    ids: untrained.map((p) => p._id),
                    promptRefresh: true,
                  }),
              }
        }
      />
    );
  };

  const renderSidebar = () => {
    if (!aimingCx.pitch) {
      return;
    }

    return (
      <PitchListSidebar
        training={!!listStore.dialogTraining}
        onMatchesChanged={(newPitch) => {
          if (newPitch) {
            return;
          }

          sendSelected('onMatchesChanged', true);
        }}
        onVideoChanged={async (video_id) => {
          const pitch = aimingCx.pitch;
          if (!pitch) {
            return;
          }

          const changed = pitch.video_id !== video_id;
          if (!changed) {
            return;
          }

          // check for video errors if necessary
          if (video_id) {
            const video = videosCx.getVideo(video_id);

            if (!video) {
              NotifyHelper.error({
                message_md: `Video \`${video_id}\` does not exist in context. Please try again.`,
              });
              return;
            }

            const warnings = VideoHelper.validateSelection({
              pitch_name: pitch.name,
              position: pitch.bs,
              video: video,
            });

            // only notify the first error that comes up
            if (warnings.length > 0) {
              NotifyHelper.warning({
                message_md: warnings[0],
                inbox: true,
              });
            }
          }

          const results = await listStore.updatePitches({
            payloads: [
              {
                _id: pitch._id,
                video_id: video_id ?? '',
              },
            ],
          });
          const updatedPitch = results?.find((p) => p._id === pitch._id);
          await aimingCx.setPitch(updatedPitch);
        }}
      />
    );
  };

  const renderMachineButton = (mode: MachineButtonMode, pitch?: IPitch) => {
    switch (mode) {
      case MachineButtonMode.Unavailable: {
        return <MachineUnavailableButton className={MACHINE_BTN_CLASSES} />;
      }

      case MachineButtonMode.Calibrate: {
        return <MachineCalibrateButton className={MACHINE_BTN_CLASSES} />;
      }

      case MachineButtonMode.Train: {
        return (
          <Button
            className={MACHINE_BTN_CLASSES}
            color={RADIX.COLOR.TRAIN_PITCH}
            disabled={!matchingCx.readyToTrain()}
            onClick={() =>
              handleTrainPitches({
                ids: pitch ? [pitch._id] : [],
                promptRefresh: true,
              })
            }
          >
            {t('common.train-pitch')}
          </Button>
        );
      }

      case MachineButtonMode.Refresh: {
        if (!pitch) {
          return;
        }

        return (
          <Button
            color={RADIX.COLOR.WARNING}
            className={MACHINE_BTN_CLASSES}
            onClick={async () => {
              machineCx.resetMSHash();

              listStore.openDialog({
                dialog: PitchListDialog.DialogResetList,
                pitches: [pitch],
              });
            }}
          >
            {t('pl.refresh-model')}
          </Button>
        );
      }

      case MachineButtonMode.Send: {
        return (
          <Button
            color={RADIX.COLOR.SEND_PITCH}
            className={MACHINE_BTN_CLASSES}
            disabled={matchingCx.loading}
            onClick={() => sendSelected('send button', false)}
          >
            {t('common.load-pitch')}
          </Button>
        );
      }

      case MachineButtonMode.Fire:
      default: {
        return;
      }
    }
  };

  const renderQueueToggle = () => {
    const queueReady = !matchingCx.aggReady;

    return (
      <CommonTooltip
        trigger={
          <IconButton
            data-testid="QueueMode"
            disabled={queueReady}
            variant="soft"
            onClick={() => {
              const i = Q_DEFINITIONS.findIndex(
                (o) => o.id === state.queueDef.id
              );

              const iNext = (i + 1) % Q_DEFINITIONS.length;
              changeQueue(Q_DEFINITIONS[iNext].id);
            }}
          >
            {queueReady ? <Spinner /> : state.queueDef.icon}
          </IconButton>
        }
        text={state.queueDef.tooltip}
      />
    );
  };

  const renderTableFooter = () => {
    const pitch = aimingCx.pitch;
    const mode = localMachineButtonMode();
    // Break this out into a separate component so the entire list doesn't have to subscribe to the tags
    const safeTags = `pitch-list,${listStore.tags}`;

    return (
      <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
        <Box>{renderQueueToggle()}</Box>

        {authCx.current.role === UserRole.admin &&
          machineCx.getAutoFireButton({
            beforeToggleFn: () =>
              setState((prev) => ({ ...prev, ignoreAutoFire: true })),
          })}

        {renderMachineButton(mode, pitch)}

        {pitch && (
          <div hidden={mode !== MachineButtonMode.Fire}>
            <MachineFireButton
              ref={(elem) => (fireButton.current = elem as MachineFireButton)}
              className={MACHINE_BTN_CLASSES}
              cookiesCx={cookiesCx}
              machineCx={machineCx}
              aimingCx={aimingCx}
              hitter={hittersCx.active}
              tags={safeTags}
              beforeFire={() =>
                setState((prev) => ({ ...prev, ignoreAutoFire: false }))
              }
              ignoreAutoFire={state.ignoreAutoFire}
              onReady={() => {
                if (!machineCx.autoFire || state.ignoreAutoFire) {
                  fireButton.current?.goToReady();
                  return;
                }

                if (globalCx.dialogs.length > 0) {
                  // never auto-fire while a dialog is open
                  return;
                }

                if (mode !== MachineButtonMode.Fire) {
                  // never auto-fire while not in firing mode
                  return;
                }

                fireButton.current?.performFire('auto', 'pitch list controls');
              }}
              firing
            />
          </div>
        )}
      </Flex>
    );
  };

  const renderTrainingDialog = (trainingCx: ITrainingContext) => {
    if (!listStore.dialogTraining) {
      return;
    }

    if (!listStore.managePitches) {
      return;
    }

    if (!matchingCx.aggReady) {
      return;
    }

    if (!listStore.managePitches) {
      return;
    }

    if (listStore.managePitches.length === 0) {
      return;
    }

    const mode = authCx.effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      const pitches = listStore.managePitches ?? [];

      return (
        <TrainingDialog
          key={listStore.dialogTraining}
          identifier="PL-TrainingDialog"
          machineCx={machineCx}
          trainingCx={trainingCx}
          pitches={pitches}
          threshold={machineCx.machine.training_threshold}
          onClose={() => {
            listStore.closeDialog({
              dialog: PitchListDialog.DialogTraining,
            });
            onEndTraining(pitches);
          }}
        />
      );
    }

    return (
      <PresetTrainingDialog
        key={listStore.dialogTraining}
        identifier="PL-PT-TrainingDialog"
        machineCx={machineCx}
        trainingCx={trainingCx}
        updatePitches={listStore.updatePitches}
        pitches={listStore.managePitches ?? []}
        onClose={() => {
          listStore.closeDialog({
            dialog: PitchListDialog.DialogTraining,
          });
          onEndTraining(listStore.managePitches);
        }}
      />
    );
  };

  const onCSVChange = (files: File[]): Promise<void> => {
    return listsCx.updateListViaCSV(files).then((success) => {
      if (success) {
        NotifyHelper.success({
          message_md: 'CSV import processed successfully!',
        });
        return;
      }

      NotifyHelper.error({
        message_md: `CSV import was not processed successfully. ${ERROR_MSGS.CONTACT_SUPPORT}`,
      });
    });
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Box className={RADIX.VFLEX.WRAPPER} flexGrow="1">
        <CommonContentWithSidebar
          left={renderBody()}
          right={renderSidebar()}
          hideSidebar={!aimingCx.pitch}
          vFlex
        />
      </Box>

      <TrainingProvider
        mode={authCx.effectiveTrainingMode()}
        afterTrainingMsg={afterTrainingMsg}
      >
        <TrainingContext.Consumer>
          {(trainingCx) => renderTrainingDialog(trainingCx)}
        </TrainingContext.Consumer>
      </TrainingProvider>

      {listStore.dialogResetList && (
        <RefreshListDialog
          pitches={listStore.managePitches ?? []}
          onRefresh={() => {
            if (!listStore.managePitches) {
              return;
            }

            const canRebuild = getCanRefresh(listStore.managePitches);
            listStore.rebuild(canRebuild.map((p) => p._id));
            onChangeTrained();
          }}
        />
      )}

      {listStore.dialogDeletePitches && (
        <DeletePitchesDialog
          key={listStore.dialogDeletePitches}
          pitches={listStore.managePitches ?? []}
          onDelete={() => {
            machineCx.resetMSHash();
            // deleted pitch may still be in the queue
            onChangeTrained();
          }}
        />
      )}

      {listStore.dialogDeleteList && (
        <PitchListsContext.Consumer>
          {(listsCx) => (
            <CommonConfirmationDialog
              key={listStore.dialogDeleteList}
              identifier="DeleteListDialog"
              title={t('common.delete-x', {
                x: t('common.pitch-list'),
              }).toString()}
              description={t('common.confirm-delete-x', {
                x: listStore.active?.name ?? '(no name)',
              }).toString()}
              action={{
                label: 'common.delete',
                color: RADIX.COLOR.DANGER,
                onClick: () => {
                  if (!listStore.active) {
                    return;
                  }

                  listsCx
                    .deleteLists([listStore.active._id])
                    .then((success) => {
                      if (success) {
                        sectionsCx.tryGoHome();
                      }
                    });
                },
              }}
            />
          )}
        </PitchListsContext.Consumer>
      )}

      <CommonSimpleFileUploader
        ref={(elem) => (fileInput.current = elem as CommonSimpleFileUploader)}
        id="pitch-list-uploader"
        acceptedTypes={['text/csv']}
        notifyMode="aggregate"
        onChange={(files) => onCSVChange(files)}
        hidden
      />

      <RemoteKeydownListener
        machineCx={machineCx}
        suspendFn={() => globalCx.dialogs.length > 0}
        onFire={() => {
          const mode = localMachineButtonMode();
          switch (mode) {
            case MachineButtonMode.Fire: {
              fireButton.current?.performFire(
                'manual',
                'clicker event: fire',
                machineCx.machine.remote_hotkeys?.delay_ms
              );
              return;
            }

            case MachineButtonMode.Send: {
              sendSelected('remote control', false);
              return;
            }

            default: {
              return;
            }
          }
        }}
        onNext={() => {
          changeActivePitch({
            delta: 1,
            delay_ms: DELAY_REMOTE_SEND_MS,
            autoSend: true,
          });
        }}
        onPrev={() => {
          changeActivePitch({
            delta: -1,
            delay_ms: DELAY_REMOTE_SEND_MS,
            autoSend: true,
          });
        }}
      />
    </ErrorBoundary>
  );
};
