import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

/**
 * A custom hook that wraps useEffect so that the callback only runs when there have been deep-comparison changes to the dependencies.
 * Useful for when you want to avoid unnecessary re-renders when the dependencies are non-primative values (e.g. objects, arrays, etc..)
 * This likely has some overhead due to the deep comparisons, so only use it when the callback is expensive.
 */
const useDeepCompareEffect = (
  callback: () => void,
  dependencies: any[]
): void => {
  // To make sure this runs on mount, just like useEffect normally does
  const firstRunRef = useRef(true);
  const currentDependenciesRef = useRef<any[]>(dependencies);

  useEffect(() => {
    if (firstRunRef.current) {
      firstRunRef.current = false;
      callback();
      return;
    }

    const hasChanged = dependencies.some(
      (dep, index) => !isEqual(dep, currentDependenciesRef.current[index])
    );

    if (hasChanged) {
      currentDependenciesRef.current = dependencies;
      callback();
    }
  }, [dependencies, callback]);
};

export default useDeepCompareEffect;
