import { Skeleton } from '@radix-ui/themes';
import { PitchListHoc } from 'components/sections/pitch-list';
import { AimingProvider } from 'contexts/aiming.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { useContext } from 'react';

export const List = () => {
  const { active } = useContext(PitchListsContext);

  if (!active) {
    return <Skeleton />;
  }

  return (
    <AimingProvider
      // reset when active list changes
      key={`aiming-${active._id}`}
    >
      <PitchListHoc
        // reset when active list changes
        key={`pitch-list-${active?._id}`}
      />
    </AimingProvider>
  );
};
