import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'RefreshListDialog';

interface IProps {
  pitches: IPitch[];
  onRefresh: () => void;
}

export const RefreshListDialog: React.FC<IProps> = ({ pitches, onRefresh }) => {
  const isSingle = pitches.length === 1;

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonConfirmationDialog
        identifier={COMPONENT_NAME}
        maxWidth={RADIX.DIALOG.WIDTH.MD}
        title={`Refresh ${isSingle ? 'Model' : 'Models'}`}
        content={
          <ReactMarkdown
            children={[
              `Are you sure you want to refresh pitch model data for the following ${
                isSingle ? 'pitch' : 'pitches'
              } from this list?`,
              PitchListHelper.enumeratePitches(pitches, 5),
              '**Warning: this cannot be undone**',
            ].join('\n\n')}
          />
        }
        action={{
          onClick: onRefresh,
        }}
      />
    </ErrorBoundary>
  );
};
