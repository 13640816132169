import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { IPitchListsContext } from 'contexts/pitch-lists/lists.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import env from 'config';
import {
  createListSlice,
  ListSlice,
} from 'components/sections/pitch-list/store/list-slice';
import {
  createDialogsSlice,
  DialogsSlice,
} from 'components/sections/pitch-list/store/dialogs-slice';

export const MAX_SEARCH_LIMIT = 1_000;
export const SEARCH_ID = '--SEARCH--';

const ENABLE_DEV_TOOLS = !env.production;

// Passed in when PitchListStoreProvider is initialized
export type PitchListStoreProps = {
  search?: boolean;
};

export type PitchListContextDependencies = {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  listsCx: IPitchListsContext;
};

export type PitchListStorePropsAndDependencies = PitchListContextDependencies &
  PitchListStoreProps;

export type PitchListState = ListSlice & DialogsSlice;
export type PitchListStore = ReturnType<typeof createPitchListStore>;

// Dependency injection due to tight coupling of the Pitch List with various contexts
// PitchListStore is initialized with access to authCx, machineCx, matchingCx, listsCx
// https://zustand.docs.pmnd.rs/guides/initialize-state-with-props
export const createPitchListStore = (
  props: PitchListStorePropsAndDependencies
) =>
  createStore<PitchListState>()(
    devtools(
      // Pass zustand args (i.e. set and get functions)
      (...args) => ({
        // Initialize the store by merging props & dependencies with each slice's state
        ...props,
        ...createListSlice(props)(...args), // ListSlice needs props to determine it's initial state
        ...createDialogsSlice(...args),
      }),
      // No need to log to devtools in production
      { enabled: ENABLE_DEV_TOOLS }
    )
  );
