import { createContext, PropsWithChildren, useContext, useRef } from 'react';
import { useStore } from 'zustand';
import {
  createPitchListStore,
  PitchListStoreProps,
  PitchListState,
  PitchListStore,
} from 'components/sections/pitch-list/store/pitch-list-store';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';

// https://zustand.docs.pmnd.rs/guides/initialize-state-with-props
// Ironically we're required to use a context for dependency injection in Zustand
export const PitchListStoreContext = createContext<PitchListStore | null>(null);

export const PitchListStoreProvider = ({
  children,
  ...props
}: PropsWithChildren<PitchListStoreProps>) => {
  const storeRef = useRef<PitchListStore>();

  // PitchListStore dependencies
  const authCx = useContext(AuthContext);
  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);
  const listsCx = useContext(PitchListsContext);

  if (!storeRef.current) {
    storeRef.current = createPitchListStore({
      authCx,
      machineCx,
      matchingCx,
      listsCx,
      ...props,
    });
  }

  return (
    <PitchListStoreContext.Provider value={storeRef.current}>
      {children}
    </PitchListStoreContext.Provider>
  );
};

export const usePitchListStore = <T,>(
  selector: (state: PitchListState) => T
): T => {
  const store = useContext(PitchListStoreContext);
  if (!store) throw new Error('Missing PitchListStore.Provider in the tree');
  return useStore(store, selector);
};
