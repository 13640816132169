import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import ReactMarkdown from 'react-markdown';
import { usePitchListStore } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { useShallow } from 'zustand/react/shallow';

const COMPONENT_NAME = 'DeletePitchesDialog';

interface IProps {
  pitches: IPitch[];
  onDelete: () => void;
}

export const DeletePitchesDialog = ({ pitches, onDelete }: IProps) => {
  const listStore = usePitchListStore(
    useShallow(({ deletePitches }) => ({
      deletePitches,
    }))
  );

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonConfirmationDialog
        identifier={COMPONENT_NAME}
        title="common.delete"
        content={
          <ReactMarkdown
            children={[
              `Are you sure you want to remove the following pitch(es) from this list? **Warning: This cannot be undone.**`,
              PitchListHelper.enumeratePitches(pitches, 5),
            ].join('\n\n')}
          />
        }
        action={{
          label: 'common.delete',
          color: RADIX.COLOR.DANGER,
          onClick: () => {
            listStore
              .deletePitches(pitches.map((p) => p._id))
              .then((success) => {
                if (success) {
                  onDelete();
                }
              });
          },
        }}
      />
    </ErrorBoundary>
  );
};
