import { Box, Flex, Strong } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonTextInput } from 'components/common/form/text';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches';
import { useContext, useState } from 'react';

const COMPONENT_NAME = 'RenameFolderDialog';

interface IProps {
  list: IPitchList;
  onClose: () => void;
}

export const RenameFolderDialog = ({ list, onClose }: IProps) => {
  const listsCx = useContext(PitchListsContext);
  const [folder, setFolder] = useState<string>(list.folder);

  const handleRename = () => {
    if (folder.length === 0) {
      NotifyHelper.error({
        message_md: 'Please provide a non-empty name for your folder.',
      });
      return;
    }

    if (folder === list.folder) {
      onClose();
      return;
    }

    listsCx
      .renameFolder({
        _parent_id: list._parent_id,
        fromFolder: list.folder,
        toFolder: folder,
      })
      .then((success) => {
        if (success) {
          onClose();
        }
      });
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        width={RADIX.DIALOG.WIDTH.MD}
        title={t('common.rename-x', {
          x: t('pl.folder'),
        }).toString()}
        loading={listsCx.loading}
        content={
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            <Box>
              <p>
                <Strong>{t('pl.warning-all-lists-in-folder')}</Strong>
              </p>
              <p>
                {t('common.current')}: <Strong>{list.folder ?? ''}</Strong>
              </p>
            </Box>

            <CommonFormGrid columns={1}>
              <Box>
                <CommonTextInput
                  id="rename-folder-folder"
                  placeholder="Type in a new folder or path"
                  value={folder}
                  onChange={(v) => setFolder(v ?? '')}
                />
              </Box>
            </CommonFormGrid>
          </Flex>
        }
        buttons={[
          {
            ...DEFAULT_ACCEPT_BTN,
            onClick: handleRename,
          },
        ]}
        onClose={onClose}
      />
    </ErrorBoundary>
  );
};
