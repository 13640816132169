import { CopyIcon } from '@radix-ui/react-icons';
import { Box, Flex, Heading, IconButton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CopyPitchesDialogHoC } from 'components/common/dialogs/copy-pitches';
import { ErrorBoundary } from 'components/common/error-boundary';
import { PlateView } from 'components/common/plate-view';
import { CommonVideoPreview } from 'components/common/video-preview';
import { AimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { HittersContext, IHittersContext } from 'contexts/hitters.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import {
  GAME_STATUS_DISABLE_ACTION_MSG,
  GameStatus,
} from 'lib_ts/enums/mlb.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import { useContext, useEffect, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { usePitchListStore } from './store/use-pitch-list-store';

const COMPONENT_NAME = 'PitchListSidebar';

interface IProps {
  // while training, plate view should not be provided any matching context to prevent re-renders as shots come in
  training?: boolean;

  onMatchesChanged: (newPitch: boolean) => void;
  onVideoChanged: (video_id: string | undefined) => void;
}

export const PitchListSidebar = (props: IProps) => {
  const listStore = usePitchListStore(
    useShallow(({ reloadPitches }) => ({
      reloadPitches,
    }))
  );

  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);
  const videosCx = useContext(VideosContext);
  const aimingCx = useContext(AimingContext);
  const authCx = useContext(AuthContext);
  const cookiesCx = useContext(CookiesContext);

  // This sidebar component is also used by MlbStatsBrowse, where HittersContext is not provided
  const hittersCx: IHittersContext | undefined = useContext(HittersContext);

  const lastVideoId = useRef<string | undefined>();

  const [videoPlayback, setVideoPlayback] = useState<IVideoPlayback>();
  const [dialogSaveAs, setDialogSaveAs] = useState<number>();

  const updateVideo = async (id?: string) => {
    lastVideoId.current = id;

    if (!id) {
      setVideoPlayback(undefined);
      return;
    }

    const playback = await videosCx.getCachedPlayback(id);

    if (id !== lastVideoId.current) {
      // handles when user changes pitches rapidly and the playback can't load in time
      return;
    }

    setVideoPlayback(playback);
  };

  useEffect(() => {
    // Make sure this is only called when changed and on mount
    updateVideo(aimingCx.pitch?.video_id);
  }, [aimingCx.pitch?.video_id]);

  const aimed = aimingCx.getAimed({
    training: false,
    usingShots: [],
  });

  const renderDialog = () => {
    if (!dialogSaveAs || !aimed) {
      return;
    }

    return (
      <CopyPitchesDialogHoC
        key={dialogSaveAs}
        identifier="PitchListSidebarSavePitchDialog"
        reloadPitches={listStore.reloadPitches}
        description={t('pl.complete-form-to-save-pitch-to-list').toString()}
        pitches={[aimed.pitch]}
        onCreated={() => setDialogSaveAs(undefined)}
        onClose={() => setDialogSaveAs(undefined)}
      />
    );
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex
        data-identifier="PLSidebar"
        direction="column"
        gap={RADIX.FLEX.GAP.MD}
      >
        <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
          <Box
            // allows the pitch name to truncate without pushing save button out of view
            minWidth="0"
          >
            <Heading
              size={RADIX.HEADING.SIZE.MD}
              className="cursor-help"
              title={t('pd.batters-pov').toString()}
              truncate
            >
              {t('pl.location-adjustment')}
            </Heading>
            <Heading
              size={RADIX.HEADING.SIZE.SM}
              color={RADIX.COLOR.SECONDARY}
              truncate
            >
              {aimed?.pitch.name ?? t('common.unnamed-pitch')}
            </Heading>
          </Box>
          <Box>
            <IconButton
              title={t('common.save-as').toString()}
              color={RADIX.COLOR.NEUTRAL}
              variant={RADIX.BUTTON.VARIANT.BORDERLESS}
              onClick={() => {
                if (authCx.gameStatus === GameStatus.InProgress) {
                  NotifyHelper.warning({
                    message_md: GAME_STATUS_DISABLE_ACTION_MSG,
                  });
                  return;
                }

                setDialogSaveAs(Date.now());
              }}
            >
              <CopyIcon />
            </IconButton>
          </Box>
        </Flex>

        <Flex justify="center">
          <Box>
            <PlateView
              cookiesCx={cookiesCx}
              authCx={authCx}
              machineCx={machineCx}
              matchingCx={matchingCx}
              drawShots={!props.training}
              pitch={aimingCx.pitch}
              hitter={hittersCx?.active}
              onUpdate={(location) => aimingCx.setPlate(location)}
              onMatchesChanged={props.onMatchesChanged}
              border
            />
          </Box>
        </Flex>

        {aimed && (
          <CommonVideoPreview
            previewPx={aimed.ms.px}
            previewPz={aimed.ms.pz}
            playback={videoPlayback}
            selectConfig={{
              px: aimed.pitch.bs.px,
              video_id: aimed.pitch.video_id,
              onChange: (video_id) => {
                // will clear the video when X is clicked
                updateVideo(video_id);

                // parent needs to know (e.g. to update the pitch record in db)
                props.onVideoChanged(video_id);
              },
            }}
          />
        )}
      </Flex>

      {renderDialog()}
    </ErrorBoundary>
  );
};
