import { useContext, useEffect, useState } from 'react';
import { Flex, Grid, Box } from '@radix-ui/themes';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTableButton } from 'components/common/table/button';
import { HittersContext } from 'contexts/hitters.context';
import { RADIX } from 'lib_ts/enums/radix-ui';
import env from 'config';
import { SearchPitchesDialog } from './dialogs';
import { CheckedContext } from 'contexts/layout/checked.context';
import { usePitchListStore } from './store/use-pitch-list-store';
import { useShallow } from 'zustand/react/shallow';

// Do we need this search key?
/* interface IProps {
  searchKey: number;
} */

const PitchListToolbar = () => {
  const [searchName, setSearchName] = useState('');
  const [dialogSearch, setDialogSearch] = useState<number>();

  const checkedCx = useContext(CheckedContext);
  const hittersCx = useContext(HittersContext);

  const listStore = usePitchListStore(
    useShallow(
      ({
        loading,
        search,
        tags,
        searchCriteria,
        setTags,
        setSearchCriteria,
      }) => ({
        loading,
        search,
        tags,
        searchCriteria,
        setTags,
        setSearchCriteria,
      })
    )
  );

  // Keep our controlled input value in sync with searchCriteria.name (in case it changes from elsewhere like SearchPitchesDialog)
  useEffect(() => {
    setSearchName(listStore.searchCriteria.name || '');
  }, [listStore.searchCriteria.name]);

  const performSearch = (name: string) => {
    checkedCx.resetChecked();
    listStore.setSearchCriteria({
      ...listStore.searchCriteria,
      name,
    });
  };

  return (
    <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
      <Flex gap={RADIX.FLEX.GAP.SM}>
        <Grid flexGrow="1" columns="3" gap={RADIX.FLEX.GAP.SM}>
          <Box>{hittersCx.getInput('level')}</Box>
          <Box>{hittersCx.getInput('hitter')}</Box>
          <Box>
            <CommonTextInput
              id="pitch-list-search"
              name="name"
              placeholder="common.search-placeholder"
              value={searchName}
              disabled={listStore.loading}
              onChange={(v = '') => setSearchName(v)}
              onKeyUp={(e) => {
                if (e.code === 'Enter') {
                  performSearch(searchName);
                }
              }}
            />
          </Box>
        </Grid>

        <Box>
          <CommonTableButton
            label="common.search"
            className="btn-block"
            variant="soft"
            onClick={() => performSearch(searchName)}
          />
        </Box>
        <Box>
          <CommonTableButton
            label="common.advanced-filters"
            className="btn-block"
            variant="soft"
            onClick={() => setDialogSearch(Date.now())}
          />
        </Box>
      </Flex>

      {env.enable.fire_tags && (
        <Flex gap={RADIX.FLEX.GAP.SM}>
          <Box>
            <CommonTextInput
              id="pitch-list-tags"
              value={listStore.tags.toUpperCase()}
              placeholder="Tags (comma-delimited)"
              onChange={(v) => listStore.setTags(v?.toUpperCase() ?? '')}
            />
          </Box>
        </Flex>
      )}

      {dialogSearch && (
        <SearchPitchesDialog
          key={dialogSearch}
          onClose={() => {
            checkedCx.resetChecked();
            setDialogSearch(undefined);
          }}
        />
      )}
    </Flex>
  );
};

export default PitchListToolbar;
