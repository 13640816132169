import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

const COMPONENT_NAME = 'ResetTrainingDialog';

interface IProps {
  pitches: IPitch[];
  onClose: (success: boolean) => void;
}

export const ResetTrainingDialog: React.FC<IProps> = ({ pitches, onClose }) => {
  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);

  const isSingle = pitches.length === 1;

  const handleReset = () => {
    const hashes = ArrayHelper.unique(
      pitches.map(
        (p) => getMSFromMSDict(p, machineCx.machine).ms?.matching_hash ?? ''
      )
    );

    if (hashes.length === 0) {
      onClose(false);
      return;
    }

    matchingCx
      .archiveShots({
        machineID: machineCx.machine.machineID,
        ball_type: machineCx.machine.ball_type,
        matching_hashes: hashes,
      })
      .then((success) => {
        if (!success) return;

        // Reload shots for the hashes since the archive would have reset the dictionary
        matchingCx.updateHashes({
          matching_hashes: hashes,
          includeHitterPresent: false,
          includeLowConfidence: true,
        });

        if (pitches.findIndex((p) => p._id === machineCx.lastPitchID) !== -1) {
          machineCx.resetMSHash();
        }

        onClose(true);
      });
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        width={RADIX.DIALOG.WIDTH.SM}
        title="common.reset-training-data"
        loading={matchingCx.loading}
        content={
          <ReactMarkdown
            children={[
              t('pl.confirm-reset-x', {
                x: t(
                  isSingle ? 'common.pitch' : 'common.pitches'
                ).toLowerCase(),
              }),
              PitchListHelper.enumeratePitches(pitches, 5),
              t('pl.warning-reset-impact'),
            ].join('\n\n')}
          />
        }
        buttons={[
          {
            label: 'common.continue',
            color: RADIX.COLOR.WARNING,
            onClick: handleReset,
          },
        ]}
        onClose={() => onClose(false)}
      />
    </ErrorBoundary>
  );
};
