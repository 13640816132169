import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import { StateCreator } from 'zustand';
import { PitchListState } from 'components/sections/pitch-list/store/pitch-list-store';
import { PitchListDialog } from 'lib_ts/enums/pitch-list.enums';

// e.g. { dialogDeletePitches: Date.now() }
type DialogOpenStatuses = Partial<Record<PitchListDialog, number>>;

export type DialogsSlice = DialogOpenStatuses & {
  // The specific pitch(es) used within the dialogs
  managePitches?: IPitch[];

  // Actions
  openDialog: (args: {
    dialog: PitchListDialog;
    pitches?: IPitch[];
    calledBy?: string;
  }) => void;

  closeDialog: (args: { dialog: PitchListDialog }) => void;
};

// Takes props via dependency injection and returns a zustand slice
type CreateDialogsSlice = StateCreator<
  PitchListState,
  [['zustand/devtools', never]],
  [],
  DialogsSlice
>;

/*
  We could be using this store to reduce the number of dialog-related pitch list re-renders,
  but there's not much point right now because globalCx.dialogs causes a full component
  tree render every time a dialog is opened/closed anyways. Need to decouple that before we
  can reap the benefits of this store.
*/
export const createDialogsSlice: CreateDialogsSlice = (set, get) => ({
  openDialog: ({ dialog, pitches }) => {
    const payload: Partial<DialogOpenStatuses> & {
      managePitches?: IPitch[];
    } = {
      [dialog]: Date.now(),
    };

    if (pitches) {
      // Only set managePitches if it's passed in
      payload.managePitches = pitches;
    }

    set(payload, undefined, 'pitchList/openDialog');
  },

  closeDialog: ({ dialog }) => {
    set(
      {
        [dialog]: undefined,
        managePitches: undefined,
      },
      undefined,
      'pitchList/closeDialog'
    );
  },
});
