import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { AccessTab } from 'components/common/pitch-lists/manage-card/access-tab';
import { DetailsTab } from 'components/common/pitch-lists/manage-card/details-tab';
import { CommonTabs } from 'components/common/tabs';
import { PitchListState } from 'components/sections/pitch-list/store/pitch-list-store';
import { IAuthContext } from 'contexts/auth.context';
import { IMachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitchList } from 'lib_ts/interfaces/pitches/i-pitch-list';
import React from 'react';

const COMPONENT_NAME = 'ManageCardDialog';

enum TabKey {
  Details = 'Details',
  Access = 'Access',
}

interface IProps extends IBaseDialog {
  authCx: IAuthContext;
  machineCx: IMachineContext;
  activeList: PitchListState['active'];
}

interface IState {
  activeTab: TabKey;

  list: IPitchList;
  loading: boolean;
}

/** used for creating, copying, and editing pitch lists */
export class ManageCardDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const list = props.activeList;

    if (!list) {
      throw new Error(`Not active list!`);
    }

    /** load the current values from list context */
    this.state = {
      loading: false,
      list: { ...list },
      activeTab: TabKey.Details,
    };
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.LG}
          title="Manage Player Card"
          content={
            <CommonTabs
              value={this.state.activeTab}
              onValueChange={(value) => {
                this.setState({ activeTab: value as TabKey });
              }}
              tabs={[
                {
                  value: TabKey.Details,
                  label: 'common.details',
                  content: (
                    <PitchListsContext.Consumer>
                      {(listsCx) => (
                        <DetailsTab list={this.state.list} listsCx={listsCx} />
                      )}
                    </PitchListsContext.Consumer>
                  ),
                },
                {
                  value: TabKey.Access,
                  label: 'admin.access',
                  content: (
                    <AccessTab
                      list_id={this.state.list._id}
                      authCx={this.props.authCx}
                    />
                  ),
                },
              ]}
            />
          }
          loading={this.state.loading}
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
