import { NotifyHelper } from 'classes/helpers/notify.helper';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { addMilliseconds } from 'date-fns';
import { ResetPlateMode } from 'enums/machine.enums';
import {
  IAimingBaseResult,
  IAimingRequest,
  IAimingResult,
} from 'interfaces/i-pitch-aiming';
import { ISendConfig } from 'interfaces/i-pitch-list';
import { AimingHelper } from 'lib_ts/classes/aiming.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMergedMSDict, getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { StaticVideoType } from 'lib_ts/enums/machine-msg.enum';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { IMachineStateMsg } from 'lib_ts/interfaces/machine-msg/i-machine-state';
import { DEFAULT_PLATE, IPitch, IPlateLoc } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import { createContext, FC, ReactNode, useContext, useState } from 'react';

const CONTEXT_NAME = 'AimingContext';
const CHECK_ASSERTS = false;

export interface IAimingContext {
  pitch: IPitch | undefined;
  setPitch: (
    pitch: IPitch | undefined,
    options?: {
      // force the plate to match the pitch's traj plate location
      resetPlate?: ResetPlateMode;
      loadShots?: boolean;
      // provide to auto-send the new pitch (with saved location) to machine
      sendConfig?: ISendConfig;
    }
  ) => Promise<void>;

  // for plate view to reference
  plate: IPlateLoc;

  readonly setPlate: (loc: IPlateLoc) => void;

  readonly getAimed: (
    config: Partial<IAimingRequest>
  ) => IAimingResult | undefined;

  readonly sendToMachine: (config: ISendConfig) => Promise<void>;

  readonly updateMatches: (
    limit?: number
  ) => Promise<IMachineShot[] | undefined>;

  readonly checkRequireSend: () => boolean;

  // set this to slightly in the future when sending to machine so fire button immediately shows loading until after
  loadingUntil: Date;
}

const DEFAULT: IAimingContext = {
  pitch: undefined,
  setPitch: () => new Promise(() => console.debug('not init')),

  plate: DEFAULT_PLATE,
  setPlate: () => console.debug('not init'),

  getAimed: () => undefined,

  sendToMachine: () => new Promise(() => console.debug('not init')),

  updateMatches: () => new Promise(() => []),

  checkRequireSend: () => true,

  loadingUntil: new Date(),
};

export const AimingContext = createContext(DEFAULT);

interface IProps {
  // when provided, every shot update query will include the value for newerThan (e.g. when training)
  newerThan?: string;

  children: ReactNode;
}

export const AimingProvider: FC<IProps> = (props) => {
  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);
  const videosCx = useContext(VideosContext);

  const [_pitch, _setPitch] = useState(DEFAULT.pitch);
  const [_plate, _setPlate] = useState<IPlateLoc>(DEFAULT.plate);

  const [_sentHash, _setSentHash] = useState<string | undefined>();
  const [_loadingUntil, _setLoadingUntil] = useState(DEFAULT.loadingUntil);

  // bare minimum with pitch and ms aimed at target
  const _getAimedBase = (
    config: IAimingRequest
  ): IAimingBaseResult | undefined => {
    const FN_NAME = `${CONTEXT_NAME} > _getAimedBase`;

    if (!_pitch) {
      return;
    }

    const ms = getMSFromMSDict(_pitch, machineCx.machine).ms;
    if (!ms) {
      console.warn(`${FN_NAME}: no ms found`);
      return;
    }

    if (!_pitch.bs || !ms || !_pitch.traj) {
      /** should not trigger, but will be filtered out to be safe */
      console.warn({
        event: `${FN_NAME}: cannot rotate pitch, invalid data detected`,
        pitch: _pitch,
        invalid_bs: !_pitch.bs,
        invalid_ms: !ms,
        invalid_traj: !_pitch.traj,
      });

      NotifyHelper.warning({
        message_md: `Data for pitch "${_pitch.name}" is invalid. Please see console for more info.`,
        delay_ms: 0,
      });

      return;
    }

    const chars = AimingHelper.aimWithShots({
      source: FN_NAME,
      chars: {
        bs: _pitch.bs,
        ms: ms,
        traj: _pitch.traj,

        seams: _pitch.seams,
        breaks: _pitch.breaks,
        priority: _pitch.priority ?? BuildPriority.Spins,
      },
      release: _pitch.bs,
      plate_location: _plate,
      plate_distance: machineCx.machine.plate_distance,
      shots: config.usingShots,
      stepSize: config?.stepSize,
    });

    const plate = TrajHelper.getPlateLoc(chars.traj);

    if (CHECK_ASSERTS) {
      AimingHelper.checkAssertions({
        chars: chars,
        plate: plate,
        refPlate: _plate,
      });
    }

    const result: IPitch = {
      ..._pitch,
      bs: chars.bs,
      traj: chars.traj,
      msDict: getMergedMSDict(machineCx.machine, [chars.ms], _pitch.msDict),
    };

    return {
      pitch: result,
      ms: chars.ms,
      usingShots: config.usingShots,
    };
  };

  // adds video fallback and ball type to create MS msg
  const _getAimed = (
    config: Partial<IAimingRequest>
  ): IAimingResult | undefined => {
    if (!_pitch) {
      return undefined;
    }

    const safeShots =
      config.usingShots ??
      (_pitch && matchingCx ? matchingCx.safeGetShotsByPitch(_pitch) : []);

    const base = _getAimedBase({
      training: !!config.training,
      stepSize: config.stepSize,
      usingShots: safeShots,
    });

    if (!base) {
      return undefined;
    }

    const safeVideoID = config.training
      ? StaticVideoType.training_short
      : // use || instead of ?? because video_id might be an empty string or undefined
        base.pitch.video_id ||
        (base.pitch.bs.px > 0
          ? machineCx.machine.default_video_lhp ?? StaticVideoType.default_LHP
          : machineCx.machine.default_video_rhp ?? StaticVideoType.default_RHP);

    const msg: IMachineStateMsg = {
      ...base.ms,
      ball_type: machineCx.machine.ball_type,
      video_uuid: safeVideoID,
      training: !!config.training,
      pitch_info: {
        target_traj: base.pitch.traj,
      },
    };

    const output: IAimingResult = {
      usingShots: base.usingShots,
      pitch: base.pitch,
      ms: base.ms,
      msg: msg,
      msgHash: MiscHelper.hashify(msg),
    };

    return output;
  };

  const _updateMatches = async (pitch: IPitch, limit?: number) => {
    await matchingCx.updatePitches({
      pitches: [pitch],
      newerThan: props.newerThan,
      includeHitterPresent: false,
      includeLowConfidence: true,
      limit: limit,
    });

    return matchingCx.safeGetShotsByPitch(pitch);
  };

  const _sendToMachine = async (
    pitch: IPitch,
    location: IPlateLoc,
    options: ISendConfig
  ) => {
    const aimed = _getAimed({
      training: options.training,
      usingShots: pitch && matchingCx.safeGetShotsByPitch(pitch),
    });

    if (!aimed) {
      return;
    }

    const video = options.training
      ? undefined
      : videosCx.getVideo(aimed.msg.video_uuid);

    if (!options.skipPreview) {
      machineCx.sendPitchPreview({
        trigger: options.trigger,
        current: aimed.pitch,
      });
    }

    const result = await machineCx.sendTarget({
      source: `${CONTEXT_NAME} using ${aimed.usingShots.length} shots`,
      msMsg: aimed.msg,
      pitch: aimed.pitch,
      video: video,
      plate: location,
      list: options.list,
      hitter_id: options.hitter?._id,
      trigger: options.trigger,
    });

    if (result.success) {
      _setLoadingUntil(addMilliseconds(new Date(), 500));
      _setSentHash(result.hash);
    }

    options.onSuccess?.(result.success);
  };

  const state: IAimingContext = {
    pitch: _pitch,
    setPitch: async (pitch, options) => {
      // forces resend to machine
      _setSentHash(undefined);
      _setPitch(pitch);

      // follow-up actions are only relevant for actual pitches
      if (!pitch) {
        return;
      }

      if (options?.resetPlate) {
        const trajPlate = TrajHelper.getPlateLoc(pitch.traj);

        switch (options.resetPlate) {
          case ResetPlateMode.Default: {
            _setPlate(DEFAULT_PLATE);
            break;
          }

          case ResetPlateMode.PitchBackup: {
            _setPlate(pitch.plate_loc_backup ?? trajPlate);
            break;
          }

          case ResetPlateMode.PitchTraj: {
            _setPlate(trajPlate);
            break;
          }

          default: {
            break;
          }
        }
      }

      if (options?.loadShots) {
        const existingShots = matchingCx.safeGetShotsByPitch(pitch);
        if (!existingShots || existingShots.length === 0) {
          // update matches (if matchingCx is provided) whenever changing pitches
          await _updateMatches(pitch);
        }
      }

      // sending logic needs to go after update matches above
      if (options?.sendConfig) {
        const defaultLocation = TrajHelper.getPlateLoc(pitch.traj);
        _sendToMachine(pitch, defaultLocation, options.sendConfig);
      } else {
        _setSentHash(undefined);
      }
    },

    plate: _plate,
    setPlate: (loc) => {
      // forces resend to machine
      _setSentHash(undefined);
      _setPlate(loc);
    },

    getAimed: _getAimed,

    checkRequireSend: () => !_sentHash || _sentHash !== machineCx.lastMSHash,

    loadingUntil: _loadingUntil,

    sendToMachine: async (options) => {
      if (!_pitch) {
        return;
      }

      await _sendToMachine(_pitch, _plate, options);
    },

    updateMatches: async (limit) => {
      if (!_pitch) {
        return;
      }

      return _updateMatches(_pitch, limit);
    },
  };

  return (
    <AimingContext.Provider value={state}>
      {props.children}
    </AimingContext.Provider>
  );
};
