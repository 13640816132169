// TODO: Use dialog identifiers? E.g. PitchListCopyPitchesDialog, PitchListEditListDialog, etc.
export enum PitchListDialog {
  // confirm
  DialogDeleteList = 'dialogDeleteList',
  DialogDeletePitches = 'dialogDeletePitches',
  DialogResetList = 'dialogResetList',

  // list
  DialogCopyList = 'dialogCopyList',
  DialogEditList = 'dialogEditList',
  DialogCard = 'dialogCard',
  DialogRenameFolder = 'dialogRenameFolder',

  // selected pitch
  DialogData = 'dialogData',
  DialogEdit = 'dialogEdit',
  DialogEditBreaks = 'dialogEditBreaks',
  DialogEditSpins = 'dialogEditSpins',
  DialogOptimize = 'dialogOptimize',

  // manage pitches
  DialogChangeVideo = 'dialogChangeVideo',
  DialogCopy = 'dialogCopy',
  DialogReset = 'dialogReset',
  DialogTraining = 'dialogTraining',

  // misc
  DialogSearch = 'dialogSearch',
}
